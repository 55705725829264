import { ErrorMessage, Field, Formik } from 'formik';
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react'
import { Button } from 'primereact/button';
import { showToast } from '../../App';
import { UpdateUser } from '../../network/Users.network';
import "../../styles.css"
import Required from '../../components/Required/Required';
import { InputTextarea } from 'primereact/inputtextarea';
import { GetInterests } from '../../network/Interests.network';
import { MultiSelect } from 'primereact/multiselect';
import { ProgressSpinner } from 'primereact/progressspinner';

const EditUser = (props) => {
    const [interests, setInterests] = useState([])
    const [loading, setLoading] = useState(false)


    const fetchInterests = async () => {
        try {
            setLoading(true)
            await GetInterests().then(response => {
                if (response?.status === 200) {
                    setInterests(response?.data?.data)
                } else {
                    showToast('error', 'Error Getting Interests')
                }
            })
        } catch (error) {
            showToast('error', 'Error fetching interests.')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (props.visible) {
            fetchInterests()
        }
    }, [props])

    const header = (
        <h1 className='text-center text-2xl text-custom-blue'>Edit User</h1>
    )

    return (
        <Dialog
            header={header}
            visible={props.visible}
            onHide={() => props.setVisible(false)}
            className={loading ? 'w-96 h-96' : 'w-auto'}
        >
            <Formik
                initialValues={{
                    firstName: props?.user?.firstName,
                    lastName: props?.user?.lastName,
                    email: props?.user?.email,
                    phone: props?.user?.phone,
                    title: props?.user?.title,
                    bio: props.user?.bio,
                    interestUuids: props.user?.interests?.map(int => int?.uuid || int)
                }}
                validate={values => {
                    const errors = {};
                    if (!values.firstName) {
                        errors.firstName = 'First name is required.';
                    }
                    if (!values.lastName) {
                        errors.lastName = 'Last name is required.';
                    }

                    // if (!values.phone) {
                    //     errors.phone = 'Phone is required.';
                    // }

                    if (!values.email) {
                        errors.email = 'Email is required';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = 'Invalid email address';
                    }
                    return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true)
                    await UpdateUser(values, props.user.uuid).then(response => {
                        if (response?.status === 200) {
                            props.setUsers(prev => {
                                return prev.map(user => {
                                    if (user.uuid === props.user.uuid) {
                                        return response?.data?.data;
                                    } else {
                                        return user;
                                    }
                                });
                            });
                            showToast('success', 'User updated successfully!')
                            props.setVisible(false)
                        } else {
                            return showToast('error', response?.data?.error ? response?.data?.error : 'Error updating user.')
                        }

                    }).catch(error => {
                        console.error('Error submitting form:', error);
                        showToast('error', 'An error occurred while submitting the form.');
                    })
                        .finally(() => {
                            setSubmitting(false)
                        });
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <>
                        {loading ? <ProgressSpinner style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                            :
                            <form onSubmit={handleSubmit} className='text-custom-blue flex flex-col justify-center items-center gap-6 mt-6 w-full'>
                                <div className='flex lg:flex-row flex-col gap-5 w-full'>
                                    <div className="flex flex-col gap-2">
                                        <span className="p-float-label">
                                            <Field as={InputText} id="firstName" name="firstName" />
                                            <label htmlFor="firstName">First name <Required /></label>
                                        </span>
                                        <ErrorMessage name="firstName" component="div" className="text-red-500" />
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <span className="p-float-label">
                                            <Field as={InputText} id="lastName" name="lastName" />
                                            <label htmlFor="lastName">Last name <Required /></label>
                                        </span>
                                        <ErrorMessage name="lastName" component="div" className="text-red-500" />
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <span className="p-float-label">
                                            <Field as={InputText} id="email" name="email" />
                                            <label htmlFor="email">Email <Required /></label>
                                        </span>
                                        <ErrorMessage name="email" component="div" className="text-red-500" />
                                    </div>
                                </div>
                                <div className='flex lg:flex-row flex-col gap-5 items-center w-full'>
                                    <div className="flex flex-col gap-2">
                                        <span className="p-float-label">
                                            <Field as={InputText} id="phone" label={"phone"} name="phone" />
                                            <label htmlFor="phone">Phone</label>
                                        </span>
                                        <ErrorMessage name="phone" component="div" className="text-red-500" />
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <span className="p-float-label">
                                            <Field as={InputText} id="title" label={"title"} name="title" />
                                            <label htmlFor="title">Title</label>
                                        </span>
                                        <ErrorMessage name="title" component="div" className="text-red-500" />
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <span className="p-float-label">
                                            <Field as={InputTextarea} id="bio" label={"bio"} name="bio" />
                                            <label htmlFor="title">Bio</label>
                                        </span>
                                        <ErrorMessage name="title" component="div" className="text-red-500" />
                                    </div>
                                </div>
                                <div className='flex lg:flex-row flex-col gap-5  w-full'>
                                    {/* Interests */}
                                    <div className="flex flex-col gap-2 w-full">
                                        <Field
                                            as={MultiSelect}
                                            id="interestUuids"
                                            name="interestUuids"
                                            value={values.interestUuids}
                                            options={interests || null}
                                            optionLabel="interestName"
                                            optionValue="uuid"
                                            onChange={handleChange}
                                            placeholder="Select Interests"
                                            className="w-full max-w-52"
                                            filter
                                            showClear
                                        />
                                        <ErrorMessage name="interestUuids" component="div" className="text-red-500" />
                                    </div>
                                </div>
                                <Button
                                    type="submit"
                                    label={isSubmitting ? 'Submitting...' : 'Submit'}
                                    className="bg-custom-blue text-white hover:bg-light-blue-2 hover:text-custom-blue border-custom-blue w-full"
                                    disabled={isSubmitting}
                                />
                            </form>}
                    </>
                )}
            </Formik>
        </Dialog>
    )
}

export default EditUser