import { PrivateApiCall } from "../config/interceptors";
import { errorCatch } from "../helpers/error";

async function UploadImages(id, data) {
    const apiCall = await PrivateApiCall.put(`/admin/upload/image/${id}`, data)
      .then((response) => response)
      .catch((error) => errorCatch(error, "Error adding image"));
    return apiCall;
}

async function UploadDocuments(id, data) {
    const apiCall = await PrivateApiCall.put(`/admin/upload/file/${id}`, data)
      .then((response) => response)
      .catch((error) => errorCatch(error, "Error adding file"));
    return apiCall;
}

async function DeleteDocument(id, data) {
    const apiCall = await PrivateApiCall.put(`/admin/upload/delete-file/${id}`, data)
      .then((response) => response)
      .catch((error) => errorCatch(error, "Error adding file"));
    return apiCall;
}

async function DeleteImage(id, data) {
  const apiCall = await PrivateApiCall.put(`/admin/upload/delete-image/${id}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error adding image"));
  return apiCall;
}

  export {
    UploadDocuments,
    UploadImages,
    DeleteDocument,
    DeleteImage
  }