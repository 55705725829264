

const DashboardLayout = ({children, ...props} ) => {
    return (
        <div className='flex flex-col w-full h-full border solid border-2 rounded-lg bg-white'>
            <div className='border-b-2 font-bold flex flex-row items-center justify-between solid px-4 py-2 w-full'>
                <h1 className='text-3xl font-bold'>{props.title}</h1>
                <div className='px-2 py-1 border solid border-1 rounded-lg bg-custom-blue hover:bg-white cursor-pointer text-white hover:text-custom-blue' onClick={props.handleClick}>
                    <i className={`pi ${props.loading && 'pi-spin'} pi-sync`} style={{ fontSize: '1rem' }}></i>
                </div>
            </div>
            <div className='flex justify-center min-h-96'>
                {children}
            </div>
        </div>
    )
}

export default DashboardLayout;