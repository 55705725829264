import { PrivateApiCall } from "../config/interceptors";
import { errorCatch } from "../helpers/error";

async function GetEvents() {
    const apiCall = await PrivateApiCall.get("/admin/event")
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error getting events"));
  return apiCall;
}

async function CreateEvent(data) {
  const apiCall = await PrivateApiCall.post("/admin/event/create", data)
  .then((response) => response)
  .catch((error) => errorCatch(error, "Error creating event"));
return apiCall;
}

async function UpdateEvent(data, id){
  const apiCall = await PrivateApiCall.put(`/admin/event/update/${id}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error updating event"));
  return apiCall;
}

async function DeleteEvent(data){
  const apiCall = await PrivateApiCall.delete("/admin/event/delete", {data: data})
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error deleting event"));
  return apiCall;
}

async function ActivateEvent(id, data){
  const apiCall = await PrivateApiCall.put(`/admin/event/activate/${id}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error updating event"));
  return apiCall;
}

async function PublishEvent(id, data){
  const apiCall = await PrivateApiCall.put(`/admin/event/publish/${id}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error updating event"));
  return apiCall;
}

export  {
  GetEvents,
  CreateEvent,
  DeleteEvent,
  UpdateEvent,
  ActivateEvent,
  PublishEvent
}