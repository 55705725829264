import { ErrorMessage, Field, Formik } from 'formik';
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext';
import React, { useState, useEffect } from 'react'
import { Button } from 'primereact/button';
import { showToast } from '../../App';
import { GetMerchants } from '../../network/Merchants.network';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import "../../styles.css"
import { UpdateGroup } from '../../network/Groups.network';
import { GetInterests } from '../../network/Interests.network';

const EditGroup = (props) => {
    const [loading, setLoading] = useState(false)
    const [merchants, setMerchants] = useState([])
    const [interests, setInterests] = useState([])

    const fetchInterests = async () => {
        try {
            setLoading(true)
            await GetInterests().then(response => {
                if (response?.status === 200) {
                    setInterests(response?.data?.data)
                } else {
                    showToast('error', 'Error Getting Interests')
                }
            })
        } catch (error) {
            showToast('error', 'Error fetching interests.')
        } finally {
            setLoading(false)
        }
    }

    const fetchMerchants = async () => {
        try {
            setLoading(true)
            await GetMerchants().then(response => {
                if (response?.status === 200) {
                    setMerchants(response?.data?.data.map(merchant => (
                        {
                            name: merchant?.merchantName,
                            code: merchant?.uuid
                        }
                    )))
                } else {
                    showToast('error', 'Error fetching merchants.')
                }
            })
        } catch (error) {
            showToast('error', 'Error fetching merchants.')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (props.visible) {
            setLoading(true)
            fetchMerchants()
            fetchInterests()
            setLoading(false)
        }
    }, [props.visible])

    const header = (
        <h1 className='text-center text-2xl text-custom-blue'>Edit Group</h1>
    )

    return (
        <Dialog
            header={header}
            visible={props.visible}
            onHide={() => props.setVisible(false)}
            className='w-auto'
        >
            <Formik
                initialValues={{
                    title: props.group.title,
                    description: props.group.description ? props.group.description : '',
                    keyPoints: props.group.keyPoints?.length > 0 ? props.group.keyPoints.join(',') : '',
                    medias: props.group.medias,
                    isPublic: props.group.isPublic,
                    nextEvent: props.group.nextEvent,
                    merchantUuid: props.group?.owner?.uuid,
                    interestUuid: props.group?.interest?.uuid
                }}

                validate={values => {
                    const errors = {};
                    if (!values.title) {
                        errors.title = 'Title is required.';
                    }
                    if (!values.interestUuid) {
                        errors.interestUuid = 'Interest is required.';
                    }
                    if (!values.merchantUuid) {
                        errors.merchantUuid = 'Merchant is required.';
                    }
                    if (values?.keyPoints?.includes(',')) {
                        const keyPointsArray = values?.keyPoints?.split(',').map(item => item.trim());
                        const invalidKeyPoint = keyPointsArray.some(point => point.length === 0);
                        if (invalidKeyPoint) {
                            errors.keyPoints = 'Invalid format';
                        }
                    }
                    return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true)
                    if (values.keyPoints.includes(',')) {
                        values.keyPoints = values?.keyPoints?.split(',')
                    } else if (values.keyPoints === '') {
                        values.keyPoints = []
                    } else {
                        values.keyPoints = [values.keyPoints]
                    }

                    await UpdateGroup(values, props.group.uuid).then(response => {
                        if (response?.status === 200) {
                            props.setGroups(prev => {
                                return prev.map(group => {
                                    if (group.uuid === props.group.uuid) {
                                        return response?.data?.data;
                                    } else {
                                        return group;
                                    }
                                });
                            });
                            showToast('success', 'Group updated successfully!')
                            props.setVisible(false)
                        } else {
                            return showToast('error', response?.data?.error ? response?.data?.error : 'Error updating group.')
                        }

                    }).catch(error => {
                        console.error('Error submitting form:', error);
                        showToast('error', 'An error occurred while submitting the form.');
                    })
                        .finally(() => {
                            setSubmitting(false)
                        });
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (<form onSubmit={handleSubmit} className='text-custom-blue flex flex-col justify-center items-center gap-5 mt-5 w-full'>
                    <div className='flex lg:flex-row flex-col gap-5 w-full'>
                        <div className="flex flex-col gap-2">
                            <span className="p-float-label">
                                <Field as={InputText} id="title" label={"title"} name="title" value={values?.title} onChange={handleChange} />
                                <label htmlFor="title">Title</label>
                            </span>
                            <ErrorMessage name="title" component="div" className="text-red-500" />
                        </div>
                        <div className="flex flex-col gap-2">
                            <span className="p-float-label">
                                <Field as={InputText} id="description" label={"description"} name="description" value={values?.description} onChange={handleChange} />
                                <label htmlFor="description">Description</label>
                            </span>
                            <ErrorMessage name="description" component="div" className="text-red-500" />
                        </div>
                        <div className="flex flex-col gap-2">
                            <span className="p-float-label">
                                <Field as={InputText} id="keyPoints" label={"keyPoints"} name="keyPoints" value={values?.keyPoints} onChange={handleChange} />
                                <label htmlFor="keyPoints">Key Points</label>
                            </span>
                            <ErrorMessage name="keyPoints" component="div" className="text-red-500" />
                        </div>
                    </div>
                    <div className='flex lg:flex-row flex-col gap-5 items-center'>
                        <div className="flex flex-col">
                            <Field
                                as={Dropdown}
                                id="merchantUuid"
                                name="merchantUuid"
                                value={values.merchantUuid}
                                options={merchants || null}
                                optionLabel="name"
                                optionValue="code"
                                onChange={handleChange}
                                placeholder="Select Merchant"
                            />
                            <ErrorMessage name="merchantUuid" component="div" className="text-red-500" />
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                            <Field
                                as={Dropdown}
                                id="interestUuid"
                                name="interestUuid"
                                value={values.interestUuid}
                                options={interests || null}
                                optionLabel="interestName"
                                optionValue="uuid"
                                onChange={handleChange}
                                placeholder="Select Interest"
                                className="w-full"
                            />
                            <ErrorMessage name="interestUuid" component="div" className="text-red-500" />
                        </div>
                    </div>
                    <div className='flex lg:flex-row flex-col gap-5'>
                        <div className="flex align-items-center">
                            <Checkbox inputId="isPublic" name="isPublic" variant='filled' value="isPublic" className='border border-custom-blue rounded-lg solid' onChange={handleChange} checked={values.isPublic} />
                            <label htmlFor="isPublic" className="ml-2">Public</label>
                        </div>
                        <div className="flex align-items-center">
                            <Checkbox inputId="medias" name="medias" variant='filled' value="medias" className='border border-custom-blue rounded-lg solid' onChange={handleChange} checked={values.medias} />
                            <label htmlFor="medias" className="ml-2">Media</label>
                        </div>
                        <div className="flex align-items-center">
                            <Checkbox inputId="nextEvent" name="nextEvent" variant='filled' value="nextEvent" className='border border-custom-blue rounded-lg solid' onChange={handleChange} checked={values.nextEvent} />
                            <label htmlFor="nextEvent" className="ml-2">Next Event</label>
                        </div>
                    </div>
                    <Button
                        type="submit"
                        label={isSubmitting ? 'Submitting...' : 'submit'}
                        className="bg-custom-blue text-white hover:bg-light-blue-2 hover:text-custom-blue border-custom-blue w-full"
                        disabled={isSubmitting || loading}
                    />
                </form>
                )}
            </Formik>
        </Dialog>
    )
}

export default EditGroup