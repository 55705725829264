import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
// import { buttonsPermissions } from "../../Helpers/PermissionsHelpers";
import PagesLayout from "../../layout/pagesLayout";
// import { showToast } from "../../App";
// import moment from "moment";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import AddAdmin from "./AddAdmin";
import { ActivateAdmin, DeleteAdmin, GetAdmins, VerifyAdmin } from "../../network/Admins.network";
import { showToast } from "../../App";
import { Tag } from "primereact/tag";
import EditAdmin from "./EditAdmins";
import { ConvertDateTime } from "../../helpers/convertDateTime";
import { classNames } from "primereact/utils";

const items = [{ label: "Admin" }];

function Admin(props) {
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [admins, setAdmins] = useState([]);
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [admin, setAdmin] = useState({})
    const [addModalVisible, setAddModalVisible] = useState(false)

    const dt = useRef(null);

    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            lastName: { value: "", matchMode: "contains" },
            firstName: { value: "", matchMode: "contains" },
            email: { value: "", matchMode: "contains" },
            phone: { value: "", matchMode: "contains" },
            verifiedAt: { value: "", matchMode: "contains" },
            createddate: { value: "", matchMode: "contains" },
        },
    });

    const fetchAdmins = async () => {
        try {
            setLoading(true)
            await GetAdmins().then(response => {
                if (response?.status === 200) {
                    setAdmins(response?.data?.data)
                    setTotalRecords(response?.data?.data?.length)
                } else {
                    showToast('error', 'Error Getting Admins')
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchAdmins()
    }, []);


    const applyPaginationAndFilters = () => {
        let filteredData = admins;

        if (!admins) {
            return []; // Return an empty array if tableRecords data is null
        }
        // Apply filters
        Object.keys(lazyState.filters).forEach((key) => {
            const filterValue = lazyState.filters[key].value;
            if (filterValue) {
                filteredData = filteredData.filter((item) =>
                    String(item[key]).toLowerCase().includes(filterValue.toLowerCase())
                );
            }
        });

        // Apply sorting
        if (lazyState.sortField && lazyState.sortOrder) {
            const sortMultiplier = lazyState.sortOrder === 1 ? 1 : -1;
            filteredData.sort((a, b) => {
                const valueA = a[lazyState.sortField];
                const valueB = b[lazyState.sortField];
                if (valueA < valueB) {
                    return -1 * sortMultiplier;
                } else if (valueA > valueB) {
                    return 1 * sortMultiplier;
                }
                return 0;
            });
        }

        // Apply pagination
        const { first, rows } = lazyState;
        const paginatedData = filteredData.slice(first, first + rows);

        return paginatedData;
    };

    const onPage = (event) => {
        setlazyState(event);
    };
    const onSort = (event) => {
        setlazyState(event);
    };

    const onFilter = (event) => {
        event["first"] = 0;
        setlazyState(event);
    };

    const header = (
        <div className="flex items-center justify-end gap-2">
            <Button
                label='Create'
                type="button"
                icon="pi pi-user-plus"
                onClick={() => setAddModalVisible(true)}
                tooltip="Add New Admin"
                className="bg-custom-blue text-white hover:bg-white hover:text-custom-blue border-custom-blue"
                tooltipOptions={{ position: 'top' }}
            />
        </div>
    );

    const handleVerify = async (rowData) => {
        try {
            setLoading(true);
            let data = { isVerified: rowData?.isVerified ? false : true }
            const response = await VerifyAdmin(rowData?.uuid, data);
            if (response?.status === 200) {
                setAdmins(prev => {
                    return prev.map(admin => {
                        if (admin.uuid === rowData?.uuid) {
                            return {
                                ...admin, // Spread the existing admin data
                                verifiedAt: response?.data?.verifiedAt === null ? null : response?.data?.verifiedAt, // Overwrite with the updated data
                                isVerified: response?.data?.isVerified
                            };
                        } else {
                            return admin;
                        }
                    });
                });

            } else {
                showToast('error', 'Error verifying admin');
            }
        } catch (error) {
            console.error('Something went wrong: ', error);
            showToast('error', 'Something went wrong. Please try again');
        } finally {
            setLoading(false);
        }
    }

    const handleActivate = async (rowData) => {
        try {
            setLoading(true);
            let data = { isActive: rowData?.isActive ? false : true }
            const response = await ActivateAdmin(rowData?.uuid, data);
            if (response?.status === 200) {
                setAdmins(prev => {
                    return prev.map(admin => {
                        if (admin.uuid === rowData?.uuid) {
                            return {
                                ...admin, // Spread the existing admin data
                                isActive: response?.data?.data?.isActive
                            };
                        } else {
                            return admin;
                        }
                    });
                });
                showToast('success', `${rowData?.isActive ? 'Admin Inactivated successfully!' : 'Admin activated sucessfully!'}`)
            } else {
                showToast('error', 'Error activating admin');
            }
        } catch (error) {
            console.error('Something went wrong: ', error);
            showToast('error', 'Something went wrong. Please try again');
        } finally {
            setLoading(false);
        }
    }

    const actionTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="flex flex-row gap-2 items-center">
                <Button tooltip={rowData?.isActive ? 'set Inactive' : 'Activate'} onClick={() => handleActivate(rowData)} tooltipOptions={{ position: 'top' }} icon={rowData?.isActive ? 'pi pi-minus' : "pi pi-check"} style={{ height: '30px', width: '35px' }} size="small" className={`${rowData?.isActive ? 'bg-red-700 hover:text-red-700 hover:border-red-700 border-red-700 text-white' : 'bg-green-800 hover:text-green-800 hover:border-green-800 border-green-800 text-white'} hover:bg-white `} />
                    <Button tooltip={rowData?.isVerified ? 'Unverify' : 'Verify'} onClick={() => handleVerify(rowData)} tooltipOptions={{ position: 'top' }} icon={rowData?.isVerified ? 'pi pi-minus-circle' : "pi pi-check-circle"} style={{ height: '30px', width: '35px' }} size="small" className={`${rowData?.isVerified ? 'bg-red-700 hover:text-red-700 hover:border-red-700 border-red-700 text-white' : 'bg-green-800 hover:text-green-800 hover:border-green-800 border-green-800 text-white'} hover:bg-white `} />
                    <Button tooltip="Edit Admin" onClick={() => { setAdmin(rowData); setEditModalVisible(true) }} tooltipOptions={{ position: 'top' }} icon="pi pi-user-edit" style={{ height: '30px', width: '35px' }} size="small" className="bg-custom-blue text-white hover:bg-white hover:text-custom-blue border-custom-blue" />
                    <Button icon="pi pi-delete-left" tooltip="Delete Admin" tooltipOptions={{ position: 'top' }} onClick={() => confirm2(rowData)} style={{ height: '30px', width: '35px' }} size="small" className="bg-red-700 hover:bg-white hover:text-red-700 border-red-700 hover:border-red-700" />
                </div>
            </React.Fragment>
        );
    };


    const accept = async (uuid) => {
        await DeleteAdmin({ uuids: [uuid] }).then(response => {
            if (response?.status === 200) {
                const newAdmins = admins.filter(admin => admin?.uuid !== uuid)
                setAdmins(newAdmins)
                setTotalRecords(prev => prev - 1)
                return showToast('success', 'Admin deleted successfully')
            } else {
                return showToast('error', 'Error deleting Admin. Please try again!')
            }
        }).catch((error) => {
            console.error('Error deleting Admin:', error);
            showToast('error', 'An error occurred while deleting Admin.');
        })
    }

    const confirm2 = (data) => {
        const acceptWithUserData = () => {
            // Pass the data to the accept function
            accept(data?.uuid);
        };
        confirmDialog({
            message: 'Do you want to delete this admin?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-danger',
            accept: acceptWithUserData
        });
    };

    const handleActive = (rowData) => {
        if (rowData?.isActive) {
            return <Tag severity="success" value="Active"></Tag>
        } else {
            return <Tag severity="danger" value="Inactive"></Tag>
        }
    }

    const verifiedBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'true-icon pi-check-circle': rowData.isVerified, 'false-icon pi-times-circle': !rowData.isVerified })}></i>;
    };

    return (
        <PagesLayout
            items={items}
            title='Admins'
        >
            <ConfirmDialog />
            <DataTable
                value={applyPaginationAndFilters()}
                header={header}
                lazy
                filterDisplay="row"
                dataKey="uuid"
                paginator
                first={lazyState.first}
                totalRecords={totalRecords}
                rows={lazyState.rows}
                onPage={onPage}
                onSort={onSort}
                sortField={lazyState.sortField}
                sortOrder={lazyState.sortOrder}
                onFilter={onFilter}
                filters={lazyState.filters}
                loading={loading}
                ref={dt}
                scrollable
                scrollHeight="60vh"
                selectionMode="single"
                rowsPerPageOptions={[5, 10, 25]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} admins"
            >
                <Column field="firstName" header="First Name" sortable filter filterPlaceholder="Search" />
                <Column field="lastName" header="Last Name" sortable filter filterPlaceholder="Search" />
                <Column field="email" header="Email" sortable filter filterPlaceholder="Search" />
                <Column field="phone" header="Phone" sortable filter filterPlaceholder="Search" />
                <Column field="role.name" header="Role Name" />
                <Column field="isActive" header="Status" body={handleActive} />
                <Column field="isVerified" header="Verified" body={verifiedBodyTemplate} />
                <Column field="verifiedAt" header="Verified At" body={rowData => rowData?.verifiedAt ? ConvertDateTime(rowData?.verifiedAt) : null} />
                <Column frozen className="actionwidth" body={actionTemplate} />
            </DataTable>
            {addModalVisible && <AddAdmin
                visible={addModalVisible}
                setVisible={setAddModalVisible}
                setAdmins={setAdmins}
                setTotalRecords={setTotalRecords}
            />}
            {editModalVisible && <EditAdmin
                visible={editModalVisible}
                setVisible={setEditModalVisible}
                admin={admin}
                setAdmins={setAdmins}
            />}
        </PagesLayout>

    );
}

export default Admin;
