import { ErrorMessage, Field, Formik } from 'formik';
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext';
import React, { useState, useEffect } from 'react'
import { Button } from 'primereact/button';
import { showToast } from '../../App';
import { UpdateOffer } from '../../network/Offers.network';
import { Calendar } from 'primereact/calendar';
import { GetGroups } from '../../network/Groups.network';
import { GetInterests } from '../../network/Interests.network';
import { GetMerchants } from '../../network/Merchants.network';
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';

const EditOffer = (props) => {
    const [loading, setLoading] = useState(false)
    const [merchantOptions, setMerchantOptions] = useState([])
    const [groupOptions, setGroupOptions] = useState([])
    const [interestOptions, setInterestOptions] = useState([])

    const header = (
        <h1 className='text-center text-2xl text-custom-blue'>Edit Offer</h1>
    )

    const getGroups = async () => {
        try {
            setLoading(true)
            await GetGroups().then(response => {
                if (response?.status === 200) {
                    setGroupOptions(response.data.data?.map(role => (
                        {
                            name: role?.title,
                            code: role?.uuid
                        }
                    )))
                }
            })
        } catch (error) {
            showToast('error', 'Error fetching groups.')
        } finally {
            setLoading(false)
        }
    }

    const getMerchants = async () => {
        try {
            setLoading(true)
            await GetMerchants().then(response => {
                if (response?.status === 200) {
                    setMerchantOptions(response.data.data?.map(role => (
                        {
                            name: role?.merchantName,
                            code: role?.uuid
                        }
                    )))
                }
            })
        } catch (error) {
            showToast('error', 'Error fetching Merchants.')
        } finally {
            setLoading(false)
        }
    }

    const getInterests = async () => {
        try {
            setLoading(true)
            await GetInterests().then(response => {
                if (response?.status === 200) {
                    setInterestOptions(response.data.data?.map(role => (
                        {
                            name: role?.interestName,
                            code: role?.uuid
                        }
                    )))
                }
            })
        } catch (error) {
            showToast('error', 'Error fetching interests.')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (props.visible) {
            getGroups()
            getMerchants()
            getInterests()
        }
    }, [props.visible])

    return (
        <Dialog
            header={header}
            visible={props.visible}
            onHide={() => props.setVisible(false)}
            className='w-auto'
        >
            <Formik
                initialValues={{
                    title: props.offer?.title,
                    content: props.offer?.content,
                    description: props?.offer?.description,
                    startDate: props.offer.startDate ? new Date(props.offer.startDate) : null,
                    endDate: props.offer.endDate ? new Date(props.offer.endDate) : null,
                    allDay: props.offer?.allDay,
                    isActive: props.offer?.isActive,
                    interestUuid: props.offer?.interest?.uuid,
                    merchantUuids: props.offer?.coOrganizers?.map(co => co?.uuid),
                    keyPoints: props.offer.keyPoints?.length > 0 ? props.offer.keyPoints.join(',') : null,
                    organizerUuid: props.offer?.organizer?.uuid,
                    groupUuids: props.offer?.groups?.map(group => group?.uuid),
                    addMerchants: props.offer?.addMerchants
                }}

                validate={values => {
                    const errors = {};
                    if (!values.title) {
                        errors.title = 'Title is required.';
                    }
                    if (!values.startDate) {
                        errors.startDate = 'Start date is required.';
                    }
                    if (!values.endDate) {
                        errors.endDate = 'End date is required.';
                    }
                    if (!values.interestUuid) {
                        errors.interestUuid = 'Interest is required.';
                    }
                    if (!values.organizerUuid) {
                        errors.organizerUuid = 'Organizer is required.';
                    }
                    if (values.keyPoints && values.keyPoints.includes(',')) {
                        const keyPointsArray = values.keyPoints?.split(',')?.map(item => item.trim());
                        const invalidKeyPoint = keyPointsArray?.some(point => point?.length === 0);
                        if (invalidKeyPoint) {
                            errors.keyPoints = 'Invalid format';
                        }
                    }
                    return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true)
                    values.keyPoints = values.keyPoints?.split(',')
                    await UpdateOffer(values, props.offer?.uuid).then(response => {
                        if (response?.status === 200) {
                            props.setOffers(prev => {
                                return prev.map(offer => {
                                if (offer.uuid === props.offer.uuid) {
                                    return response?.data?.data;
                                } else {
                                    return offer;
                                }
                                });
                            });
                            showToast('success', 'Offer updated successfully.')
                            props.setVisible(false)
                        } else {
                            return showToast('error', response?.data?.error ? response?.data?.error : 'Error updating offer.')
                        }
                    }).catch(error => {
                        console.error('Error submitting form:', error);
                        showToast('error', 'An error occurred while submitting the form.');
                    })
                        .finally(() => {
                            setSubmitting(false)
                        })
                }}
            >
                {({
                    values,
                    handleChange,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit} className='text-custom-blue flex flex-col justify-between items-center gap-8 mt-6 w-full'>
                    <div className='flex lg:flex-row flex-col gap-5 w-full justify-between'>
                        <div className="flex flex-col gap-2 w-full">
                            <span className="p-float-label">
                                <Field as={InputText} id="title" name="title" className="w-full" />
                                <label htmlFor="title">Title</label>
                            </span>
                            <ErrorMessage name="title" component="div" className="text-red-500" />
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                            <span className="p-float-label">
                                <Field as={InputText} id="content" name="content" className="w-full" />
                                <label htmlFor="content">Content</label>
                            </span>
                            <ErrorMessage name="content" component="div" className="text-red-500" />
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                            <span className="p-float-label">
                                <Field as={InputText} id="description" className="w-full" name="description" />
                                <label htmlFor="description">Description</label>
                            </span>
                            <ErrorMessage name="description" component="div" className="text-red-500" />
                        </div>
                    </div>
                    <div className='flex lg:flex-row flex-col gap-5 w-full justify-between'>
                        <div className="flex flex-col gap-2 w-full">
                            <span className="p-float-label">
                                <Field as={Calendar} showTime={true} hourFormat="12" className="w-full" id="startDate" label={"startDate"} name="startDate" />
                                <label htmlFor="startDate">Start Date</label>
                            </span>
                            <ErrorMessage name="startdate" component="div" className="text-red-500" />
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                            <span className="p-float-label">
                                <Field as={Calendar} showTime={true} className="w-full" hourFormat="12" id="endDate" label={"endDate"} name="endDate" />
                                <label htmlFor="endDate">End Date</label>
                            </span>
                            <ErrorMessage name="enddate" component="div" className="text-red-500" />
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                            <span className="p-float-label">
                                <Field as={InputText} id="keyPoints" className="w-full" name="keyPoints" />
                                <label htmlFor="keyPoints">Key Points</label>
                            </span>
                            <ErrorMessage name="keyPoints" component="div" className="text-red-500" />
                        </div>
                    </div>
                    <div className='flex lg:flex-row flex-col gap-5 w-full justify-between'>
                        <div className="flex flex-col gap-2">
                            <Field
                                as={Dropdown}
                                id="organizerUuid"
                                name="organizerUuid"
                                value={values.organizerUuid}
                                options={merchantOptions || null}
                                optionLabel="name"
                                optionValue="code"
                                onChange={handleChange}
                                placeholder="Select a Organizer"
                                filter
                            />
                            <ErrorMessage name="roleUuid" component="div" className="text-red-500" />
                        </div>
                        <div className="flex flex-col gap-2">
                            <Field
                                as={MultiSelect}
                                id="groupUuids"
                                name="groupUuids"
                                value={values.groupUuids}
                                options={groupOptions || null}
                                optionLabel="name"
                                optionValue="code"
                                onChange={handleChange}
                                placeholder="Select a Group"
                                filter
                                showClear
                            />
                            <ErrorMessage name="groupUuids" component="div" className="text-red-500" />
                        </div>
                        <div className="flex flex-col gap-2">
                            <Field
                                as={Dropdown}
                                id="interestUuid"
                                name="interestUuid"
                                value={values.interestUuid}
                                options={interestOptions || null}
                                optionLabel="name"
                                optionValue="code"
                                onChange={handleChange}
                                placeholder="Select an Interest"
                                filter
                            />
                            <ErrorMessage name="interestUuid" component="div" className="text-red-500" />
                        </div>
                    </div>
                    <div className='flex lg:flex-row flex-col gap-5 items-center justify-between w-full'>
                    <div className="flex flex-col gap-2">
                            <Field
                                as={MultiSelect}
                                id="merchantUuids"
                                name="merchantUuids"
                                value={values.merchantUuids}
                                options={merchantOptions || null}
                                optionLabel="name"
                                optionValue="code"
                                onChange={handleChange}
                                placeholder="Select CoOrganizers"
                                filter
                                showClear
                            />
                            <ErrorMessage name="merchantUuids" component="div" className="text-red-500" />
                        </div>
                        <div className="flex align-items-center">
                            <Checkbox inputId="allDay" name="allDay" variant='filled' value="allDay" className='border border-custom-blue rounded-lg solid' onChange={handleChange} checked={values.allDay} />
                            <label htmlFor="allDay" className="ml-2">All Day</label>
                        </div>                            
                        <div className="flex align-items-center">
                            <Checkbox inputId="isActive" name="isActive" variant='filled' value="isActive" className='border border-custom-blue rounded-lg solid' onChange={handleChange} checked={values.isActive} />
                            <label htmlFor="isActive" className="ml-2">Active</label>
                        </div>                            
                        <div className="flex align-items-center">
                            <Checkbox inputId="addMerchants" name="addMerchants" variant='filled' value="addMerchants" className='border border-custom-blue rounded-lg solid' onChange={handleChange} checked={values.addMerchants} />
                            <label htmlFor="addMerchants" className="ml-2">Add Merchants</label>
                        </div>
                    </div>
                        <Button
                            type="submit"
                            label={isSubmitting ? 'Updating...' : 'Update'}
                            className="bg-custom-blue text-white hover:bg-light-blue-2 hover:text-custom-blue border-custom-blue w-full"
                            disabled={isSubmitting}
                        />
                    </form>
                )}
            </Formik>
        </Dialog>
    )
}

export default EditOffer