import React, { useEffect, useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import secureLocalStorage from 'react-secure-storage';
import { showToast } from '../App';
import { loginAdmins } from '../network/Auth.network';
import ForgotPassword from '../components/ForgotPassword/ForgotPassword';
import LoginLayout from '../layout/LoginLayout';


const Login = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false)
    const [showForgotPass, setShowForgotPass] = useState(false)
    const navigate = useNavigate()
    const token = secureLocalStorage.getItem('token')

    useEffect(() => {
        if (token) {
            props.setIsLoggedin(true)
            navigate('/')
        }
    }, [token, props, navigate])



    const handleSignIn = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            await loginAdmins({ email: email, password: password }).then(response => {
                if (response?.status === 200) {
                    secureLocalStorage.setItem('token', response?.data?.data?.token)
                    secureLocalStorage.setItem('loggedin', true);
                    showToast('success', 'Logged In successfully')
                    props.setIsLoggedin(true);
                    navigate('/')
                } else {
                    setMessage('Incorrect username or password, Please try again')
                    return showToast('warn', 'Bad Credentials')
                }
            })
        } catch (error) {
            console.log('LOGIN ERROR: ' + error);
            throw error
        } finally {
            setLoading(false)
        }
    };

    return (
        <LoginLayout title={!showForgotPass ? 'Sign in to your account' : 'Forgot Password'}>
            {!showForgotPass ? <form onSubmit={handleSignIn} className=" w-full px-5 gap-5 flex flex-col">
                <span className="p-float-label mb-3">
                    <InputText
                        label={"Email address"}
                        id={"email"}
                        type={"text"}
                        className='w-full border-custom-blue'
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="email"
                        required
                    />
                    <label htmlFor="email" className='px-2'>Email</label>
                </span>
                <span className="p-float-label">
                    <InputText
                        className='w-full border-custom-blue'
                        label={"Password"}
                        id={"password"}
                        type={"password"}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="current-password"
                        required
                    />
                    <label htmlFor="password" className='px-2'>Password</label>
                </span>
                {message && <span className="text-red-500">{message}</span>}
                <div className="text-end">
                    <div className="ml-3 block text-sm leading-6 text-custom-blue underline cursor-pointer font-semibold" onClick={() => setShowForgotPass(true)}>Forgot Password</div>
                </div>
                <div className='text-center'>
                    <Button type='submit' variant="contained" className='w-full bg-custom-blue justify-center'>{loading ? 'Signing in...' : 'Sign In'}</Button>
                </div>
            </form>
                :
                <ForgotPassword setShowForgotPass={setShowForgotPass} />}
        </LoginLayout>
    )
}

export default Login