import { ErrorMessage, Field, Formik } from 'formik';
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext';
import React, { useState, useEffect } from 'react'
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox'
import { Dropdown } from 'primereact/dropdown'
import { showToast } from '../../App';
import { CreateGroup } from '../../network/Groups.network';
import { GetMerchants } from '../../network/Merchants.network';
import Required from '../../components/Required/Required';
import { GetInterests } from '../../network/Interests.network';

const AddGroup = (props) => {
    const [loading, setLoading] = useState(false)
    const [merchants, setMerchants] = useState([])
    const [interests, setInterests] = useState([])

    const fetchInterests = async () => {
        try {
            setLoading(true)
            await GetInterests().then(response => {
                if (response?.status === 200) {
                    setInterests(response?.data?.data)
                } else {
                    showToast('error', 'Error Getting Interests')
                }
            })
        } catch (error) {
            showToast('error', 'Error fetching interests.')
        } finally {
            setLoading(false)
        }
    }

    const fetchMerchants = async () => {
        try {
            setLoading(true)
            await GetMerchants().then(response => {
                if(response?.status === 200) {
                    setMerchants(response?.data?.data.map(merchant => (
                        {
                            name: merchant?.merchantName,
                            code: merchant?.uuid
                        }
                    )))
                } else {
                    showToast('error', 'Error fetching merchants.')
                }
            })
        } catch(error) {
            showToast('error', 'Error fetching merchants.')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if(props.visible) {
            fetchMerchants()
            fetchInterests()
        }
    },[props.visible])

    const header = (
        <h1 className='text-center text-2xl text-custom-blue'>Add Group</h1>
    )

    return (
        <Dialog
            header={header}
            visible={props.visible}
            onHide={() => props.setVisible(false)}
            className='w-auto'
        >
            <Formik
                initialValues={{
                    title: '',
                    description: '',
                    keyPoints: '',
                    medias: false,
                    isPublic: false,
                    nextEvent: false,
                    merchantUuid: '',
                    interestUuid: ''
                }}

                validate={values => {
                        const errors = {};
                        if (!values.title) {
                            errors.title = 'Title is required.';
                        }

                        if (!values.interestUuid) {
                            errors.interestUuid = 'Interest is required.';
                        }
                        if (!values.merchantUuid) {
                            errors.merchantUuid = 'Merchant is required.';
                        }
                        if (values.keyPoints) {
                            const keyPointsArray = values.keyPoints?.split(',').map(item => item.trim());
                            const invalidKeyPoint = keyPointsArray?.some(point => point?.length === 0);
                            if (invalidKeyPoint) {
                                errors.keyPoints = 'Invalid format';
                            }
                        }
                        return errors;
                    }}  
                    onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(true)
                        if(values.keyPoints.includes(',')){
                            values.keyPoints = values?.keyPoints?.split(',')
                        }
                        await CreateGroup(values).then(response => {
                            if (response?.status === 200) {
                                const newGroup = response?.data?.data
                                props.setGroups(prevGroups => [...prevGroups, newGroup])
                                props.setTotalRecords(prevTotalRecords => prevTotalRecords + 1)
                                showToast('success', 'Group created successfully.')
                                props.setVisible(false)
                            } else {
                                return showToast('error', response?.data?.error ? response?.data?.error : 'Error creating group.')
                            }
                        }).catch(error => {
                            console.error('Error submitting form:', error);
                            showToast('error', 'An error occurred while submitting the form.');
                        })
                        .finally(() => {
                            setSubmitting(false)
                         });
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                    }) => (
                        <form onSubmit={handleSubmit} className='text-custom-blue flex flex-col justify-center items-center gap-5 mt-5 w-full'>
                                <div className='flex lg:flex-row flex-col gap-5 w-full'>
                                    <div className="flex flex-col gap-2">
                                        <span className="p-float-label">
                                            <Field as={InputText} id="title" label={"title"} name="title" value={values?.title} onChange={handleChange} />
                                            <label htmlFor="title">Title <Required/></label>
                                        </span>
                                        <ErrorMessage name="title" component="div" className="text-red-500" />
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <span className="p-float-label">
                                            <Field as={InputText} id="description" label={"description"} name="description" value={values?.description} onChange={handleChange} />
                                            <label htmlFor="description">Description </label>
                                        </span>
                                        <ErrorMessage name="description" component="div" className="text-red-500" />
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <span className="p-float-label">
                                            <Field as={InputText} id="keyPoints" label={"keyPoints"} name="keyPoints" value={values?.keyPoints} onChange={handleChange} />
                                            <label htmlFor="keyPoints">Key Points</label>
                                        </span>
                                        <ErrorMessage name="keyPoints" component="div" className="text-red-500" />
                                    </div>
                                </div>
                                <div className='flex lg:flex-row flex-col gap-5 items-center'>
                                    <div className="flex flex-col">
                                        <Field
                                            as={Dropdown}
                                            id="merchantUuid"
                                            name="merchantUuid"
                                            value={values.merchantUuid}
                                            options={merchants || null}
                                            optionLabel="name"
                                            optionValue="code"
                                            onChange={handleChange}
                                            placeholder="Select Merchant"
                                        />
                                        <ErrorMessage name="merchantUuid" component="div" className="text-red-500" />
                                    </div>
                                    <div className="flex flex-col gap-2 w-full">
                                    <Field
                                        as={Dropdown}
                                        id="interestUuid"
                                        name="interestUuid"
                                        value={values.interestUuid}
                                        options={interests || null}
                                        optionLabel="interestName"
                                        optionValue="uuid"
                                        onChange={handleChange}
                                        placeholder="Select Interest"
                                        className="w-full"
                                    />
                                    <ErrorMessage name="interestUuid" component="div" className="text-red-500" />
                                </div>
                                </div>
                                <div className='flex lg:flex-row flex-col gap-5'>
                                    <div className="flex align-items-center">
                                        <Checkbox inputId="isPublic" name="isPublic" variant='filled' value="isPublic" className='border border-custom-blue rounded-lg solid' onChange={handleChange} checked={values.isPublic} />
                                        <label htmlFor="isPublic" className="ml-2">Public <Required/></label>
                                    </div>         
                                    <div className="flex align-items-center">
                                        <Checkbox inputId="medias" name="medias" variant='filled' value="medias" className='border border-custom-blue rounded-lg solid' onChange={handleChange} checked={values.medias} />
                                        <label htmlFor="medias" className="ml-2">Media <Required/></label>
                                    </div>         
                                    <div className="flex align-items-center">
                                        <Checkbox inputId="nextEvent" name="nextEvent" variant='filled' value="nextEvent" className='border border-custom-blue rounded-lg solid' onChange={handleChange} checked={values.nextEvent} />
                                        <label htmlFor="nextEvent" className="ml-2">Next Event <Required/></label>
                                    </div>         
                                </div>
                            <Button
                                type="submit"
                                label={isSubmitting ? 'Creating...' : 'Create'}
                                className="bg-custom-blue text-white hover:bg-light-blue-2 hover:text-custom-blue border-custom-blue w-full"
                                disabled={isSubmitting}
                            />
                        </form>
                     )}
            </Formik>
        </Dialog>
    )
}

export default AddGroup