import React from 'react'
import { BreadCrumb } from 'primereact/breadcrumb';

const home = { icon: "pi pi-home" };

const PagesLayout = ({children, ...props}) => {
  return (
    <div className='flex flex-col gap-3 text-center p-5'>
        <h1 className='text-3xl font-bold'>{props.title}</h1>
        <BreadCrumb model={props.items} home={home} className='font-semibold text-custom-blue' style={{border: 'none', background: 'transparent'}} />
        {children}
    </div>
  )
}

export default PagesLayout