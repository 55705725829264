import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import PagesLayout from "../../layout/pagesLayout";
import { showToast } from "../../App";
import * as XLSX from 'xlsx';
import { ActivateGroup, DeleteGroup, GetGroups, PublishGroup } from "../../network/Groups.network";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import AddGroup from "./AddGroup";
import EditGroup from "./EditGroup";
import { ConvertDateTime } from "../../helpers/convertDateTime";
import { handleActive, publishedBodyTemplate } from "../../helpers/status.helpers";

const items = [{ label: "Groups" }];

function Groups(props) {
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [groups, setGroups] = useState([]);
    const [addModalVisible, setAddModalVisible] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState({})
    const [editModalVisible, setEditModalVisible] = useState(false)

    const dt = useRef(null);

    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            title: { value: "", matchMode: "contains" },
            description: { value: "", matchMode: "contains" },
            merchantName: { value: "", matchMode: "contains" },
            keyPoints: { value: "", matchMode: "contains" },
        },
    });

    const fetchGroups = async () => {
        try {
            setLoading(true)
            await GetGroups().then(response => {
                if (response?.status === 200) {
                    setGroups(response?.data?.data)
                    setTotalRecords(response?.data?.data?.length)
                } else {
                    showToast('error', 'Error Getting Groups')
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchGroups()
    }, []);

    const exportXLSX = () => {
        const exportData = groups;
        if (exportData?.length > 0) {
            const exportedColumns = exportColumns.map((col) => col.dataKey);
            const exportedRows = exportData.map((offer) =>
                exportedColumns.map((col) => {
                    const value = offer[col];
                    return value;
                })
            );
            const worksheetData = [exportedColumns, ...exportedRows];

            const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Groups');

            // Generate a unique file name based on the current timestamp
            const fileName = 'groups.xlsx';

            XLSX.writeFile(workbook, fileName);
        } else {
            showToast('error', 'No data found')
        }
    };

    //Columns and data to be displayed in exported PDF and Excel files
    const cols = [
        { field: "title", header: "Title" },
        { field: "desription", header: "Description" },
        { field: "merchantName", header: "Merchant Name" },
        { field: "keyPoints", header: "KeyPoints" },

    ];

    const exportColumns = cols.map((col) => ({
        title: col.header,
        dataKey: col.field,
    }));


    const applyPaginationAndFilters = () => {
        let filteredData = groups;

        if (!groups) {
            return []; // Return an empty array if tableRecords data is null
        }
        // Apply filters
        Object.keys(lazyState.filters).forEach((key) => {
            const filterValue = lazyState.filters[key].value;
            if (filterValue) {
                filteredData = filteredData.filter((item) =>
                    String(item[key]).toLowerCase().includes(filterValue.toLowerCase())
                );
            }
        });

        // Apply sorting
        if (lazyState.sortField && lazyState.sortOrder) {
            const sortMultiplier = lazyState.sortOrder === 1 ? 1 : -1;
            filteredData.sort((a, b) => {
                const valueA = a[lazyState.sortField];
                const valueB = b[lazyState.sortField];
                if (valueA < valueB) {
                    return -1 * sortMultiplier;
                } else if (valueA > valueB) {
                    return 1 * sortMultiplier;
                }
                return 0;
            });
        }

        // Apply pagination
        const { first, rows } = lazyState;
        const paginatedData = filteredData.slice(first, first + rows);

        return paginatedData;
    };

    const onPage = (event) => {
        setlazyState(event);
    };
    const onSort = (event) => {
        setlazyState(event);
    };

    const onFilter = (event) => {
        event["first"] = 0;
        setlazyState(event);
    };

    const header = (
        <div className="flex items-center justify-between gap-2">
            <Button
                type="button"
                icon="pi pi-file"
                severity="primary"
                onClick={() => exportXLSX()}
                className='bg-green-800 text-white hover:bg-white hover:text-green-800 border border-green-800'
                tooltip="Export to Excel"
            />
            <Button
                label='Create'
                type="button"
                icon="pi pi-user-plus"
                onClick={() => setAddModalVisible(true)}
                tooltip="Add New Group"
                className="bg-custom-blue text-white hover:bg-white hover:text-custom-blue border-custom-blue"
                tooltipOptions={{ position: 'top' }}
            />
        </div>
    );

    const accept = async (uuid) => {
        await DeleteGroup({ uuids: [uuid] }).then(response => {
            if (response?.status === 200) {
                const newAdmins = groups.filter(admin => admin?.uuid !== uuid)
                setGroups(newAdmins)
                setTotalRecords(prev => prev - 1)
                return showToast('success', 'Group deleted successfully')
            } else {
                return showToast('error', 'Error deleting Group. Please try again!')
            }
        }).catch((error) => {
            console.error('Error deleting Group:', error);
            showToast('error', 'An error occurred while deleting Group.');
        })
    }

    const confirm2 = (data) => {
        const acceptWithUserData = () => {
            // Pass the data to the accept function
            accept(data?.uuid);
        };
        confirmDialog({
            message: 'Do you want to delete this group?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-danger',
            accept: acceptWithUserData
        });
    };

    const actionTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="flex flex-row gap-2 items-center">
                <Button tooltip={rowData?.isActive ? 'Inactivate' : 'Activate'} onClick={() => handleActivate(rowData)} tooltipOptions={{ position: 'top' }} icon={rowData?.isActive ? 'pi pi-minus' : "pi pi-check"} style={{ height: '30px', width: '35px' }} size="small" className={`${rowData?.isActive ? 'bg-red-700 hover:text-red-700 hover:border-red-700 border-red-700 text-white' : 'bg-green-800 hover:text-green-800 hover:border-green-800 border-green-800 text-white'} hover:bg-white `} />
                    <Button tooltip={rowData?.isPublished ? 'Unpublish' : 'Publish'} onClick={() => handlePublish(rowData)} tooltipOptions={{ position: 'top' }} icon={rowData?.isPublished ? 'pi pi-minus-circle' : "pi pi-check-circle"} style={{ height: '30px', width: '35px' }} size="small" className={`${rowData?.isPublished ? 'bg-red-700 hover:text-red-700 hover:border-red-700 border-red-700 text-white' : 'bg-green-800 hover:text-green-800 hover:border-green-800 border-green-800 text-white'} hover:bg-white `} />
                    <Button tooltip="Edit Group" onClick={() => { setSelectedGroup(rowData); setEditModalVisible(true) }} tooltipOptions={{ position: 'top' }} icon="pi pi-user-edit" style={{ height: '30px', width: '35px' }} size="small" className="bg-custom-blue text-white hover:bg-white hover:text-custom-blue border-custom-blue" />
                    <Button icon="pi pi-delete-left" tooltip="Delete Group" tooltipOptions={{ position: 'top' }} onClick={() => confirm2(rowData)} style={{ height: '30px', width: '35px' }} size="small" className="bg-red-700 hover:bg-white hover:text-red-700 border-red-700 hover:border-red-700" />
                </div>
            </React.Fragment>
        );
    };

    const handleActivate = async (rowData) => {
        try {
            setLoading(true);
            let data = { isActive: rowData?.isActive ? false : true }
            const response = await ActivateGroup(rowData?.uuid, data);
            if (response?.status === 200) {
                setGroups(prev => {
                    return prev.map(group => {
                        if (group.uuid === rowData?.uuid) {
                            return {
                                ...group, // Spread the existing admin data
                                isActive: response?.data?.data?.isActive
                            };
                        } else {
                            return group;
                        }
                    });
                });
                showToast('success', `${rowData?.isActive ? 'Group Inactivated successfully!' : 'Group activated sucessfully!'}`)
            } else {
                showToast('error', 'Error activating group');
            }
        } catch (error) {
            console.error('Something went wrong: ', error);
            showToast('error', 'Something went wrong. Please try again');
        } finally {
            setLoading(false);
        }
    }

    const handlePublish = async (rowData) => {
        try {
            setLoading(true);
            let data = { isPublished: rowData?.isPublished ? false : true }
            const response = await PublishGroup(rowData?.uuid, data);
            if (response?.status === 200) {
                setGroups(prev => {
                    return prev.map(group => {
                        if (group.uuid === rowData?.uuid) {
                            return {
                                ...group, // Spread the existing admin data
                                isPublished: data?.isPublished
                            };
                        } else {
                            return group;
                        }
                    });
                });
                showToast('success', `${rowData?.isVerified ? 'Group Unpublished successfully!' : 'Group published sucessfully!'}`)
            } else {
                showToast('error', 'Error publishing group');
            }
        } catch (error) {
            console.error('Something went wrong: ', error);
            showToast('error', 'Something went wrong. Please try again');
        } finally {
            setLoading(false);
        }
    }

    return (
        <PagesLayout
            items={items}
            title='Groups'
        >
            <ConfirmDialog />
            <DataTable
                value={applyPaginationAndFilters()}
                header={header}
                lazy
                dataKey="uuid"
                paginator
                first={lazyState.first}
                totalRecords={totalRecords}
                rows={lazyState.rows}
                onPage={onPage}
                onSort={onSort}
                sortField={lazyState.sortField}
                sortOrder={lazyState.sortOrder}
                onFilter={onFilter}
                filters={lazyState.filters}
                filterDisplay="row"
                loading={loading}
                ref={dt}
                scrollable
                scrollHeight="60vh"
                selectionMode="single"
                rowsPerPageOptions={[5, 10, 25]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} groups"
            >
                <Column field="title" header="Title" sortable filter filterPlaceholder="Search" />
                <Column field="merchantName" body={rowData => rowData?.owner?.merchantName} header="Merchant Name" filter filterPlaceholder="Search" />
                <Column field="description" header="Description" sortable filter filterPlaceholder="Search" />
                <Column field="keyPoints" header="Key Points" body={rowData => rowData?.keyPoints?.length > 0 ? rowData?.keyPoints?.join(',') : rowData?.keyPoints } filter filterPlaceholder="Search" />
                <Column field="interest.interestName" header="Interest" />
                <Column field="nextEvent" header="Next Event" />
                <Column field="medias" header="Medias" />
                <Column field="isPublic" header="Public" />
                <Column field="isActive" header="Active" body={handleActive} />
                <Column field="isPublished" header="Published" body={rowData => publishedBodyTemplate(rowData)} />
                <Column field="createdAt" header="Created Date" body={(rowData) => rowData?.createdAt ? ConvertDateTime(rowData?.createdAt) : null} />
                <Column frozen className="actionwidth" body={actionTemplate} />
            </DataTable>
            {addModalVisible && <AddGroup
                visible={addModalVisible}
                setVisible={setAddModalVisible}
                setGroups={setGroups}
                setTotalRecords={setTotalRecords}
            />}
            {editModalVisible && <EditGroup
                visible={editModalVisible}
                setVisible={setEditModalVisible}
                setGroups={setGroups}
                group={selectedGroup}
            />}
        </PagesLayout>

    );
}

export default Groups;
