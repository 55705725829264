import { ErrorMessage, Field, Formik } from 'formik';
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react'
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { showToast } from '../../App';
import { GetRoles } from '../../network/Roles.network';
import { UpdateAdmin } from '../../network/Admins.network';
import { Checkbox } from 'primereact/checkbox';

const EditAdmin = (props) => {
    const [roles, setRoles] = useState([])

    const header = (
        <h1 className='text-center text-2xl text-custom-blue'>Edit Admin</h1>
    )

    console.log(props)

    useEffect(() => {
        const fetchRoles = async () => {
            await GetRoles().then(response => {
                if (response?.status === 200) {
                    setRoles(response.data.data?.map(role => (
                        {
                            name: role?.name,
                            value: role?.uuid
                        }
                    )))
                }
            })
        }
        fetchRoles()
    }, [])

const handleUpdate = async (data) => {
    console.log(data)
    await UpdateAdmin(data, props.admin.uuid).then(response => {
        if (response?.status === 200) {
            props.setAdmins(prev => {
                return prev.map(admin => {
                  if (admin.uuid === props.admin.uuid) {
                    return response?.data?.data;
                  } else {
                    return admin;
                  }
                });
              });
            showToast('success', 'Admin updating successfully!')
            props.setVisible(false)
        } else {
            return showToast('error', response?.data?.error ? response?.data?.error : 'Error updating admin.')
        }

    }).catch(error => {
        console.error('Error submitting form:', error);
        showToast('error', 'An error occurred while submitting the form.');
    })
        .finally(() => {
            // setSubmitting(false);
        });
}
    return (
        <Dialog
            header={header}
            visible={props.visible}
            onHide={() => props.setVisible(false)}
            className='w-auto'
        >
            <Formik
                initialValues={{
                        firstName: props?.admin?.firstName,
                        lastName: props?.admin?.lastName,
                        email: props?.admin?.email,
                        phone: props?.admin?.phone,
                        roleUuid: props?.admin?.role.uuid,
                        isActive: props?.admin?.isActive
                    }}
                validate={values => {
                    const errors = {};
                    if (!values.firstName) {
                        errors.firstName = 'First name is required.';
                    }
                    if (!values.lastName) {
                        errors.lastName = 'Last name is required.';
                    }

                    if (!values.password) {
                        errors.password = 'Password is required.';
                    } else if (
                        !/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(values.password)
                    ) {
                        errors.password = 'Password must be strong.';
                    }

                    if (!values.phone) {
                        errors.phone = 'Phone is required.';
                    }
                    if (!values.roleUuid) {
                        errors.roleUuid = 'Role is required.';
                    }
                    if (!values.email) {
                        errors.email = 'Email is required';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = 'Invalid email address';
                    }
                    return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    console.log(values)
                    
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <form onSubmit={handleSubmit} className='text-custom-blue flex flex-col justify-center items-center gap-8 mt-6 w-full'>
                        <div className='flex lg:flex-row flex-col gap-5 w-full'>
                            <div className="flex flex-col gap-2">
                                <span className="p-float-label">
                                    <Field as={InputText} id="firstName" name="firstName" value={values?.firstName} onChange={handleChange} />
                                    <label htmlFor="firstName">First name</label>
                                </span>
                                <ErrorMessage name="firstName" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <span className="p-float-label">
                                    <Field as={InputText} id="lastName" name="lastName" value={values?.lastName} onChange={handleChange} />
                                    <label htmlFor="lastName">Last name</label>
                                </span>
                                <ErrorMessage name="lastName" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <span className="p-float-label">
                                    <Field as={InputText} id="email" name="email" value={values?.email} onChange={handleChange} />
                                    <label htmlFor="email">Email</label>
                                </span>
                                <ErrorMessage name="email" component="div" className="text-red-500" />
                            </div>
                        </div>
                        <div className='flex lg:flex-row flex-col gap-5 items-center w-full'>
                            <div className="flex flex-col gap-2">
                                <span className="p-float-label">
                                    <Field as={InputText} id="phone" label={"phone"} name="phone" value={values?.phone} onChange={handleChange} />
                                    <label htmlFor="phone">Phone</label>
                                </span>
                                <ErrorMessage name="phone" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <Field
                                    as={Dropdown}
                                    id="roleUuid"
                                    name="roleUuid"
                                    value={values.roleUuid}
                                    options={roles || null}
                                    optionLabel="name"
                                    onChange={handleChange}
                                    placeholder="Select a Role"
                                />
                                <ErrorMessage name="roleUuid" component="div" className="text-red-500" />
                            </div>
                            <div className="flex align-items-center">
                                <Checkbox inputId="isActive" name="isActive" variant='filled' value="isActive" className='border border-custom-blue rounded-lg solid' onChange={handleChange} checked={values.isActive} />
                                <label htmlFor="isActive" className="ml-2">Active</label>
                            </div>
                        </div>
                        <Button
                            type="button"
                            label='Update'
                            className="bg-custom-blue text-white hover:bg-light-blue-2 hover:text-custom-blue border-custom-blue w-full"
                            onClick={() => handleUpdate(values)}
                            disabled={isSubmitting}
                        />
                    </form>
                )}
            </Formik>
        </Dialog>
    )
}

export default EditAdmin