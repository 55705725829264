import { ErrorMessage, Field, Formik } from 'formik';
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react'
import { Button } from 'primereact/button';
import { showToast } from '../../App';
import { CreateInterest } from '../../network/Interests.network';

const AddInterest = (props) => {
    const [selectedImage, setSelectedImage] = useState(null)
    const [image, setImage] = useState(null)

    const header = (
        <h1 className='text-center text-2xl text-custom-blue'>Add Interest</h1>
    )

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImage(file)
            setSelectedImage(URL.createObjectURL(file));
        }
    };

    return (
        <Dialog
            header={header}
            visible={props.visible}
            onHide={() => props.setVisible(false)}
            className='w-auto'
        >
            <Formik
                initialValues={{
                    interestName: '',
                    description: ''
                }}

                validate={values => {
                    const errors = {};
                    if (!values.interestName) {
                        errors.interestName = 'Interest is required.';
                    }
                    return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true)
                    const formData = new FormData()
                    if (image) {
                        formData.append('image', image)
                    }
                    Object.keys(values).forEach(key => {
                        formData.append(key, values[key]);
                    });
                    await CreateInterest(formData).then(response => {
                        if (response?.status === 200) {
                            const newInt = response?.data?.data
                            props.setInterests(prevInt => [...prevInt, newInt])
                            props.setTotalRecords(prevTotalRecords => prevTotalRecords + 1)
                            showToast('success', 'Interest created successfully.')
                            props.setVisible(false)
                        } else {
                            return showToast('error', response?.data?.error ? response?.data?.error : 'Error creating interest.')
                        }
                    }).catch(error => {
                        console.error('Error submitting form:', error);
                        showToast('error', 'An error occurred while submitting the form.');
                    })
                        .finally(() => {
                            setSubmitting(false)
                        });
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <form onSubmit={handleSubmit} className='text-custom-blue flex flex-col justify-center items-center gap-5 mt-5 w-full'>
                        <div className='flex lg:flex-row flex-col justify-between w-full gap-4'>
                            <div className="flex flex-col gap-2">
                                <span className="p-float-label">
                                    <Field as={InputText} id="interestName" name="interestName" />
                                    <label htmlFor="interestName">Interest</label>
                                </span>
                                <ErrorMessage name="interestName" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <span className="p-float-label">
                                    <Field as={InputText} id="description" name="description" />
                                    <label htmlFor="description">Description</label>
                                </span>
                                <ErrorMessage name="description" component="div" className="text-red-500" />
                            </div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <span>
                                <input type="file" onChange={handleImageChange} />
                            </span>
                            {selectedImage && (
                                <img src={selectedImage} alt="Selected" className="mt-2" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                            )}
                        </div>
                        <Button
                            type="submit"
                            label={isSubmitting ? 'Creating...' : 'Create'}
                            className="bg-custom-blue text-white hover:bg-light-blue-2 hover:text-custom-blue border-custom-blue w-full"
                            disabled={isSubmitting}
                        />
                    </form>
                )}
            </Formik>
        </Dialog>
    )
}

export default AddInterest