import { PrivateApiCall } from "../config/interceptors";
import { errorCatch } from "../helpers/error";

async function GetGroups() {
    const apiCall = await PrivateApiCall.get("/admin/group")
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error getting Groups"));
  return apiCall;
}

async function CreateGroup(data) {
  const apiCall = await PrivateApiCall.post("/admin/group/create", data)
  .then((response) => response)
  .catch((error) => errorCatch(error, "Error creating group"));
return apiCall;
}

async function UpdateGroup(data, id){
  const apiCall = await PrivateApiCall.put(`/admin/group/update/${id}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error updating group"));
  return apiCall;
}

async function DeleteGroup(data){
  const apiCall = await PrivateApiCall.delete("/admin/group/delete", {data: data})
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error deleting group"));
  return apiCall;
}

async function ActivateGroup(id, data){
  const apiCall = await PrivateApiCall.put(`/admin/group/activate/${id}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error updating group"));
  return apiCall;
}

async function PublishGroup(id, data){
  const apiCall = await PrivateApiCall.put(`/admin/group/publish/${id}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error updating group"));
  return apiCall;
}

export  {
  GetGroups,
  CreateGroup,
  DeleteGroup,
  UpdateGroup,
  PublishGroup,
  ActivateGroup
}