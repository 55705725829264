import { PrivateApiCall } from "../config/interceptors";
import { errorCatch } from "../helpers/error";

async function GetAdmins() {
    const apiCall = await PrivateApiCall.get("/admin/admin")
      .then((response) => response)
      .catch((error) => errorCatch(error, "Error getting admins"));
    return apiCall;
  }

async function CreateAdmin(data) {
    const apiCall = await PrivateApiCall.post("/admin/admin/create", data)
      .then((response) => response)
      .catch((error) => errorCatch(error, "Error creating admin"));
    return apiCall;
}

async function DeleteAdmin(data){
    const apiCall = await PrivateApiCall.delete("/admin/admin/delete", {data: data})
      .then((response) => response)
      .catch((error) => errorCatch(error, "Error deleting admin"));
    return apiCall;
}

async function UpdateAdmin(data, id){
    const apiCall = await PrivateApiCall.put(`/admin/admin/update/${id}`, data)
      .then((response) => response)
      .catch((error) => errorCatch(error, "Error updating admin"));
    return apiCall;
}

async function VerifyAdmin(id, data){
  const apiCall = await PrivateApiCall.put(`/admin/admin/verify/${id}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error verifying admin"));
  return apiCall;
}

async function ActivateAdmin(id, data){
  const apiCall = await PrivateApiCall.put(`/admin/admin/activate/${id}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error activated admin"));
  return apiCall;
}

async function ChangeAdminPassword(data) {
  const apiCall = await PrivateApiCall.put("/admin/admin/changePassword", data)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error changing password"));
  return apiCall;
}

export  {
    GetAdmins,
    CreateAdmin,
    DeleteAdmin,
    UpdateAdmin,
    VerifyAdmin,
    ActivateAdmin,
    ChangeAdminPassword
}