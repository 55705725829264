import { Tag } from "primereact/tag"

export const handleActive = (rowData) => {
    if (rowData?.isActive) {
        return <Tag severity="success" value="Active"></Tag>
    } else {
        return <Tag severity="danger" value="Inactive"></Tag>
    }
}

export const publishedBodyTemplate = (rowData) => {
    if (rowData?.isPublished) {
        return <Tag severity="success" value="Published"></Tag>
    } else {
        return <Tag severity="danger" value="Unpublished"></Tag>
    }
}