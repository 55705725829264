export const types = [
    {
        code: 'winemaker',
        name: 'winemaker'
    },
    {
        code: 'merchant',
        name: 'merchant'
    },
    {
        code: 'association',
        name: 'association'
    }
]