import React from 'react'
import BG from '../assets/images/bg.jpg'
import LOGO from '../assets/logo.svg'

const LoginLayout = ({ children, ...props }) => {
    return (
        <div className="flex min-h-screen flex-1 bg-white text-green-950">
            <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-2">
                <div className="mx-auto w-full max-w-sm lg:w-96 flex flex-col gap-10 justify-center items-center">
                    <div className="flex flex-col items-center">
                        <img
                            className="h-14 w-auto"
                            src={LOGO}
                            alt="MyProperty"
                            sizes="100vw"
                            height={0}
                            width={0}
                        />
                        <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-center">
                            {props.title}
                        </h2>
                    </div>
                    {children}
                </div>
            </div>
            <div className="relative hidden w-0 flex-1 lg:block">
                <img
                    className="absolute inset-0 h-full w-full object-cover"
                    src={BG}
                    alt="bg"
                />
            </div>
        </div>
    )
}

export default LoginLayout