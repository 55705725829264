import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import PagesLayout from "../../layout/pagesLayout";
import { showToast } from "../../App";
import * as XLSX from 'xlsx';
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DeleteInterest } from "../../network/Interests.network";
import { GetInterests } from "../../network/Interests.network";
import AddInterest from "./AddInterest";
import EditInterest from "./EditInterest";

const items = [{ label: "Interests" }];

function Interests(props) {
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [interests, setInterests] = useState([]);
    const [addModalVisible, setAddModalVisible] = useState(false)
    const [selectedInterest, setSelectedInterest] = useState({})
    const [editModalVisible, setEditModalVisible] = useState(false)

    const dt = useRef(null);

    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            interestName: { value: "", matchMode: "contains" },
            description: { value: "", matchMode: "contains" },
        },
    });

    const fetchInterests = async () => {
        try {
            setLoading(true)
            await GetInterests().then(response => {
                if (response?.status === 200) {
                    setInterests(response?.data?.data)
                    setTotalRecords(response?.data?.data?.length)
                } else {
                    showToast('error', 'Error Getting Interest')
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchInterests()
    }, []);

    const exportXLSX = () => {
        const exportData = interests;
        if (exportData?.length > 0) {
            const exportedColumns = exportColumns.map((col) => col.dataKey);
            const exportedRows = exportData.map((offer) =>
                exportedColumns.map((col) => {
                    const value = offer[col];
                    return value;
                })
            );
            const worksheetData = [exportedColumns, ...exportedRows];

            const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Interests');

            // Generate a unique file name based on the current timestamp
            const fileName = 'interest.xlsx';

            XLSX.writeFile(workbook, fileName);
        } else {
            showToast('error', 'No data found')
        }
    };

    //Columns and data to be displayed in exported PDF and Excel files
    const cols = [
        { field: "interestName", header: "Interest" },
        { field: "description", header: "Description" },
    ];

    const exportColumns = cols.map((col) => ({
        title: col.header,
        dataKey: col.field,
    }));


    const applyPaginationAndFilters = () => {
        let filteredData = interests;

        if (!interests) {
            return []; // Return an empty array if tableRecords data is null
        }
        // Apply filters
        Object.keys(lazyState.filters).forEach((key) => {
            const filterValue = lazyState.filters[key].value;
            if (filterValue) {
                filteredData = filteredData.filter((item) =>
                    String(item[key]).toLowerCase().includes(filterValue.toLowerCase())
                );
            }
        });

        // Apply sorting
        if (lazyState.sortField && lazyState.sortOrder) {
            const sortMultiplier = lazyState.sortOrder === 1 ? 1 : -1;
            filteredData.sort((a, b) => {
                const valueA = a[lazyState.sortField];
                const valueB = b[lazyState.sortField];
                if (valueA < valueB) {
                    return -1 * sortMultiplier;
                } else if (valueA > valueB) {
                    return 1 * sortMultiplier;
                }
                return 0;
            });
        }

        // Apply pagination
        const { first, rows } = lazyState;
        const paginatedData = filteredData.slice(first, first + rows);

        return paginatedData;
    };

    const onPage = (event) => {
        setlazyState(event);
    };
    const onSort = (event) => {
        setlazyState(event);
    };

    const onFilter = (event) => {
        event["first"] = 0;
        setlazyState(event);
    };


    const header = (
        <div className="flex items-center justify-between gap-2">
            <Button
                type="button"
                icon="pi pi-file"
                severity="primary"
                onClick={() => exportXLSX()}
                className='bg-green-800 text-white hover:bg-white hover:text-green-800 border border-green-800'
                tooltip="Export to Excel"
            />
            <Button
                label='Create'
                type="button"
                icon="pi pi-user-plus"
                onClick={() => setAddModalVisible(true)}
                tooltip="Add New Interest"
                className="bg-custom-blue text-white hover:bg-white hover:text-custom-blue border-custom-blue"
                tooltipOptions={{ position: 'top' }}
            />
        </div>
    );

    const confirm2 = (data) => {
        confirmDialog({
            message: 'Do you want to delete this interest?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-danger',
            accept: async () => {
                await DeleteInterest({ uuids: [data?.uuid] }).then(response => {
                    if (response?.status === 200) {
                        const newAdmins = interests.filter(admin => admin?.uuid !== data?.uuid)
                        setInterests(newAdmins)
                        setTotalRecords(prev => prev -1)
                        return showToast('success', 'Interest deleted successfully')
                    } else {
                        return showToast('error', 'Error deleting Interest. Please try again!')
                    }
                }).catch((error) => {
                    console.error('Error deleting Interest:', error);
                    showToast('error', 'An error occurred while deleting Interest.');
                })
            }
        });
    };

    const actionTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="flex flex-row gap-2 items-center justify-end">
                    <Button tooltip="Edit Interest" onClick={() => {  setEditModalVisible(true); setSelectedInterest(rowData) }} tooltipOptions={{ position: 'top' }} icon="pi pi-pen-to-square" style={{ height: '30px', width: '35px' }} size="small" className="bg-custom-blue text-white hover:bg-white hover:text-custom-blue border-custom-blue" />
                    <Button icon="pi pi-delete-left" tooltip="Delete Interest" tooltipOptions={{ position: 'top' }} onClick={() => confirm2(rowData)} style={{ height: '30px', width: '35px' }} size="small" className="bg-red-700 hover:bg-white hover:text-red-700 border-red-700 hover:border-red-700" />
                </div>
            </React.Fragment>
        );
    };

    const imageBodyTemplate = (rowData) => {
        return <img height={50} width={50} src={rowData?.image} alt={rowData.interestName} className="w-6rem shadow-2 border-round" />;
    };

    return (
        <PagesLayout
            items={items}
            title='Interests'
        >
            <ConfirmDialog />
            <DataTable
                value={applyPaginationAndFilters()}
                header={header}
                lazy
                dataKey="uuid"
                paginator
                first={lazyState.first}
                totalRecords={totalRecords}
                rows={lazyState.rows}
                onPage={onPage}
                onSort={onSort}
                sortField={lazyState.sortField}
                sortOrder={lazyState.sortOrder}
                onFilter={onFilter}
                filters={lazyState.filters}
                filterDisplay="row"
                loading={loading}
                ref={dt}
                scrollable
                scrollHeight="60vh"
                selectionMode="single"
                rowsPerPageOptions={[5, 10, 25]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} interests"
            >
                <Column header="Image" body={imageBodyTemplate}></Column>
                <Column field="interestName" header="Interest" sortable filter filterPlaceholder="Search" />
                <Column field="description" header="Description" sortable  filter filterPlaceholder="Search" />
                <Column frozen className="actionwidth" body={actionTemplate} />
            </DataTable>
            {addModalVisible && <AddInterest
                visible={addModalVisible}
                setVisible={setAddModalVisible}
                setInterests={setInterests}
                setTotalRecords={setTotalRecords}
            />}
            {editModalVisible && <EditInterest
                visible={editModalVisible}
                setVisible={setEditModalVisible}
                interests={interests}
                setInterests={setInterests}
                interest={selectedInterest}
            />}
        </PagesLayout>

    );
}

export default Interests;
