import React, { useEffect, useRef, useState } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { GetNextEvents } from '../../network/Dashboard.network';
import { ConvertDateTime } from '../../helpers/convertDateTime';
import { Tag } from 'primereact/tag';

const EventsAnalytics = () => {
    const [events, setEvents] = useState([])
    const [loading, setLoading] = useState(false)
    const [totalRecords, setTotalRecords] = useState(0)
    const dt = useRef(null);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            title: { value: "", matchMode: "contains" },
            description: { value: "", matchMode: "contains" },
            startDate: { value: "", matchMode: "contains" },
            endDate: { value: "", matchMode: "contains" },
            keyPoints: { value: "", matchMode: "contains" },
            createdAt: { value: "", matchMode: "contains" },
        },
    });

    const fetchNextEvent = async () => {
        try{
            setLoading(true)
            await GetNextEvents().then(response => {
                if(response?.status === 200){
                    setEvents(response.data.data)
                    setTotalRecords(response.data.data.length)
                }
            })
        } catch (error){
            console.error('Error fetching next events')
            throw new Error('Error while fetching events')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchNextEvent()
    },[])

    const applyPaginationAndFilters = () => {
        let filteredData = events;

        if (!events) {
            return []; // Return an empty array if tableRecords data is null
        }
        // Apply filters
        Object.keys(lazyState.filters).forEach((key) => {
            const filterValue = lazyState.filters[key].value;
            if (filterValue) {
                filteredData = filteredData.filter((item) =>
                    String(item[key]).toLowerCase().includes(filterValue.toLowerCase())
                );
            }
        });

        // Apply sorting
        if (lazyState.sortField && lazyState.sortOrder) {
            const sortMultiplier = lazyState.sortOrder === 1 ? 1 : -1;
            filteredData.sort((a, b) => {
                const valueA = a[lazyState.sortField];
                const valueB = b[lazyState.sortField];
                if (valueA < valueB) {
                    return -1 * sortMultiplier;
                } else if (valueA > valueB) {
                    return 1 * sortMultiplier;
                }
                return 0;
            });
        }

        // Apply pagination
        const { first, rows } = lazyState;
        const paginatedData = filteredData.slice(first, first + rows);

        return paginatedData;
    };

    const onPage = (event) => {
        setlazyState(event);
    };
    const onSort = (event) => {
        setlazyState(event);
    };


    const header = (
        <div className="flex items-center justify-between gap-2">
             <h1 className='text-3xl font-bold'>Next Events</h1>
                <div className='px-2 py-1 border solid border-1 rounded-lg bg-custom-blue hover:bg-white cursor-pointer text-white hover:text-custom-blue' onClick={fetchNextEvent}>
                    <i className={`pi ${loading && 'pi-spin'} pi-sync`} style={{ fontSize: '1rem' }}></i>
                </div>
        </div>
    );

    const handleAllDay = (rowData) => {
        if (rowData?.allDay) {
            return <Tag severity="primary" value="All Day"></Tag>
        } else {
            return <Tag severity="primary" value="Not All Day"></Tag>
        }
    }

    const handleActive = (rowData) => {
        if (rowData?.isActive) {
            return <Tag severity="success" value="Active"></Tag>
        } else {
            return <Tag severity="danger" value="Inactive"></Tag>
        }
    }

    const onFilter = (event) => {
        event["first"] = 0;
        setlazyState(event);
    };

    return (
        <DataTable
                value={applyPaginationAndFilters()}
                header={header}
                lazy
                dataKey="uuid"
                paginator
                first={lazyState.first}
                totalRecords={totalRecords}
                rows={lazyState.rows}
                onPage={onPage}
                onSort={onSort}
                sortField={lazyState.sortField}
                sortOrder={lazyState.sortOrder}
                onFilter={onFilter}
                filters={lazyState.filters}
                filterDisplay="row"
                loading={loading}
                ref={dt}
                scrollable
                scrollHeight="80vh"
                selectionMode="single"
                rowsPerPageOptions={[5, 10, 25]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} events"
            >
                <Column field="title" header="Title" sortable filter filterPlaceholder="Search" />
                <Column field="description" header="Description" sortable filter filterPlaceholder="Search" />
                <Column field="startDate" header="Start Date" sortable filter body={rowData => ConvertDateTime(rowData?.startDate)} filterPlaceholder="Search" />
                <Column field="endDate" header="End Date" sortable filter body={rowData => ConvertDateTime(rowData?.endDate)} filterPlaceholder="Search" />
                <Column field="allDay" header="All Day" body={rowData => handleAllDay(rowData)} />
                <Column field="isActive" header="Active" body={handleActive} />
                <Column field="showMembers" header="Show Members" />
                <Column field="createdAt" header="Created date" sortable filter body={rowData => ConvertDateTime(rowData?.createdAt)} filterPlaceholder="Search" />
            </DataTable>
    )
}

export default EventsAnalytics