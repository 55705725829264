import { PrivateApiCall, PublicApiCall } from "../config/interceptors";
import { errorCatch } from "../helpers/error";

const headers = {
    headers: { Authorization: `PARTOF ` + localStorage.PARTOFTOKEN },
  };

async function loginAdmins(data) {
    const apiCall = await PublicApiCall.post("/auth/loginAdmin", data, headers)
      .then((response) => response)
      .catch((error) => errorCatch(error, "Error Login"));
    return apiCall;
}

async function ForgotPasswordd(data) {
  const apiCall = await PublicApiCall.post("/auth/admin/forgot-pass", data, headers)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error Forgot Password"));
  return apiCall;
}

async function ResetPassword(data,token) {
  const apiCall = await PublicApiCall.post(`/auth/resetpassword/${token}`, data, headers)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error Reseting Password"));
  return apiCall;
}

  export {
    loginAdmins,
    ForgotPasswordd,
    ResetPassword
  }