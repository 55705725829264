import { PrivateApiCall } from "../config/interceptors";
import { errorCatch } from "../helpers/error";

async function GetInterests() {
    const apiCall = await PrivateApiCall.get("/admin/interest")
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error getting interests"));
  return apiCall;
}

async function CreateInterest(data) {
  const apiCall = await PrivateApiCall.post("/admin/interest/create", data)
  .then((response) => response)
  .catch((error) => errorCatch(error, "Error creating interest"));
return apiCall;
}

async function UpdateInterest(data, id){
  const apiCall = await PrivateApiCall.put(`/admin/interest/update/${id}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error updating interest"));
  return apiCall;
}

async function DeleteInterest(data){
  const apiCall = await PrivateApiCall.delete("/admin/interest/delete", {data: data})
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error deleting interest"));
  return apiCall;
}

export  {
  GetInterests,
  CreateInterest,
  DeleteInterest,
  UpdateInterest
}