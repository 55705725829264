import { ErrorMessage, Field, Formik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Password } from 'primereact/password';
import React from 'react'
import { showToast } from '../../App';
import { ChangeAdminPassword } from '../../network/Admins.network';
import { jwtDecode } from 'jwt-decode';
import secureLocalStorage from 'react-secure-storage';

const ChangePassword = (props) => {
    const admin = jwtDecode(secureLocalStorage.getItem('token'))

    const header = (
        <h1 className='text-center text-2xl text-custom-blue'>Change Password</h1>
    )

return (
    <Dialog
        header={header}
        visible={props.visible}
        className='w-auto'
        onHide={() => props.setVisible(false)}>
        <Formik
            initialValues={{ oldPassword: '', newPassword: '', confirmPassword: '' }}
            validate={values => {
                const errors = {};
                if (!values.oldPassword) {
                    errors.oldPassword = 'Old password is required.';
                }
                if(values.oldPassword === values.newPassword){
                    errors.newPassword = 'New password should not match the old password';
                }
                if (!values.newPassword) {
                    errors.newPassword = 'New password is required.';
                } else if (
                    !/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/i.test(values.newPassword)
                ) {
                    errors.newPassword = 'Password must be strong';
                }
                
                if (!values.confirmPassword) {
                    errors.confirmPassword = 'Confirm password is required.';
                }
                if(values.newPassword !== values.confirmPassword){
                    errors.confirmPassword = 'Passwords does not match'
                }

                return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
                values.adminUuid = admin?.uuid
                await ChangeAdminPassword(values).then(response => {
                    if (response?.status === 200) {
                        showToast('success', 'Password changed successfully!')
                        props.setVisible(false)
                    } else {
                        return showToast('error', response?.data?.error ? response?.data?.error : 'Error changing password.')
                    }
                }).catch(error => {
                    console.error('Error submitting form:', error);
                    showToast('error', 'An error occurred while submitting the form.');
                })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
        >
            {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <form onSubmit={handleSubmit} className='text-custom-blue flex flex-col justify-center items-center gap-5 mt-5 w-full'>
                    <div className='flex flex-row gap-5 justify-between'>
                        <div className='flex flex-col gap-2 justify-center text-center'>
                            <span className="p-float-label">
                                <Field as={Password} id="oldPassword" name="oldPassword" value={values?.oldPassword} onChange={handleChange} />
                                <label htmlFor="oldPassword">Old Password</label>
                            </span>
                            <ErrorMessage name="oldPassword" component="div" className="text-red-500" />
                        </div>
                        <div className='flex flex-col gap-2 justify-center text-center'>
                            <span className="p-float-label">
                                <Field as={Password} id="newPassword" name="newPassword" value={values?.newPassword} onChange={handleChange} />
                                <label htmlFor="newPassword">New Password</label>
                            </span>
                            <ErrorMessage name="newPassword" component="div" className="text-red-500 mt-2" />
                        </div>
                        <div className='flex flex-col gap-2 justify-center text-center'>
                            <span className="p-float-label">
                                <Field as={Password} id="confirmPassword" name="confirmPassword" value={values?.confirmPassword} onChange={handleChange} />
                                <label htmlFor="confirmPassword">Confirm Password</label>
                            </span>
                            <ErrorMessage name="confirmPassword" component="div" className="text-red-500" />
                        </div>
                    </div>
                    <Button
                        type="submit"
                        label={isSubmitting ? 'Submitting...' : 'Submit'}
                        className="bg-custom-blue text-white hover:bg-light-blue-2 hover:text-custom-blue border-custom-blue w-full"
                        disabled={isSubmitting} />
                </form>
            )}
        </Formik>
    </Dialog>
)
}

export default ChangePassword