import { ErrorMessage, Field, Formik } from 'formik';
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext';
import React, { useState, useEffect } from 'react'
import { Button } from 'primereact/button';
import { showToast } from '../../App';
import { Calendar } from 'primereact/calendar';
import { GetInterests } from '../../network/Interests.network';
import { GetMerchants } from '../../network/Merchants.network';
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from 'primereact/checkbox';
import { UpdateEvent } from '../../network/Events.network';
import { Dropdown } from 'primereact/dropdown';
import { GetGroups } from '../../network/Groups.network';

const EditEvent = (props) => {
    const [loading, setLoading] = useState(false)
    const [merchantOptions, setMerchantOptions] = useState([])
    const [interestOptions, setInterestOptions] = useState([])
    const [groups, setGroups] = useState([])
 
    const header = (
        <h1 className='text-center text-2xl text-custom-blue'>Edit Event</h1>
    )

    const getGroups = async () => {
        try {
            setLoading(true)
            await GetGroups().then(response => {
                if (response?.status === 200) {
                    setGroups(response.data.data?.map(group => (
                        {
                            name: group?.title,
                            code: group?.uuid
                        }
                    )))
                }
            })
        } catch (error) {
            console.error('Error getting groups: ', error)
            showToast('error', 'Error fetching Groups.')
        } finally {
            setLoading(false)
        }
    }

    const getMerchants = async () => {
        try {
            setLoading(true) 
            await GetMerchants().then(response => {
                if (response?.status === 200) {
                    setMerchantOptions(response.data.data?.map(role => (
                        {
                            name: role?.merchantName,
                            code: role?.uuid
                        }
                    )))
                }
            })
        } catch (error) {
            showToast('error', 'Error fetching Merchants.')
        } finally {
            setLoading(false)
        }
    }

    const getInterests = async () => {
        try {
            setLoading(true) 
            await GetInterests().then(response => {
                if (response?.status === 200) {
                    setInterestOptions(response.data.data?.map(role => (
                        {
                            name: role?.interestName,
                            code: role?.uuid
                        }
                    )))
                }
            })
        } catch (error) {
            showToast('error', 'Error fetching interests.')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if(props.visible) {
            getMerchants()
            getInterests()
            getGroups()
        }
    },[props.visible])

    return (
        <Dialog
            header={header}
            visible={props.visible}
            onHide={() => props.setVisible(false)}
            className='w-auto'
        >
            <Formik
                initialValues={{
                    title: props.event.title,
                    description: props.event.description,
                    startDate: props.event.startDate ? new Date(props.event.startDate) : null,
                    endDate: props.event.endDate ? new Date(props.event.endDate) : null,
                    allDay: props.event.allDay,
                    isActive: props.event.isActive,
                    interestUuid: props.event.interest?.uuid,
                    merchantUuids: props.event.merchants.map(mer => mer?.uuid),
                    organizerUuid: props.event.organizer?.uuid ,
                    groupUuids: props.event.groups?.map(gr => gr?.uuid),
                    keyPoints: props.event.keyPoints?.length > 0 ? props.event?.keyPoints?.join(',') : null,
                    showMembers: props.event?.showMembers
                }}

                    validate={values => {
                        const errors = {};
                        if (!values.title) {
                            errors.title = 'Title is required.';
                        }
                        if (!values.startDate) {
                            errors.startDate = 'Start date is required.';
                        }
                        if (!values.endDate) {
                            errors.endDate = 'End date is required.';
                        }
                        if (!values.interestUuid) {
                            errors.interestUuid = 'Interest is required.';
                        }
                        if (!values.organizerUuid) {
                            errors.organizerUuid = 'Organizer is required.';
                        }
                        if (values.keyPoints) {
                            const keyPointsArray = values.keyPoints?.split(',')?.map(item => item.trim());
                            const invalidKeyPoint = keyPointsArray?.some(point => point?.length === 0);
                            if (invalidKeyPoint) {
                                errors.keyPoints = 'Invalid format';
                            }
                        }   
                        return errors;
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(true)
                        if (values.keyPoints.includes(',')) {
                            values.keyPoints = values?.keyPoints?.split(',')
                        } else if (values.keyPoints === '') {
                            values.keyPoints = []
                        } else {
                            values.keyPoints = [values.keyPoints]
                        }
                        await UpdateEvent(values, props.event.uuid).then(response => {
                            if (response?.status === 200) {
                                props.setEvents(prev => {
                                    return prev.map(event => {
                                    if (event.uuid === props.event.uuid) {
                                        return response?.data?.data;
                                    } else {
                                        return event;
                                    }
                                    });
                                });
                                showToast('success', 'Event updated successfully!')
                                props.setVisible(false)
                            } else {
                                return showToast('error', response?.data?.error ? response?.data?.error : 'Error updating event.')
                            }
                
                        }).catch(error => {
                            showToast('error', 'An error occurred while submitting the form.');
                        })
                        .finally(() => {
                            setLoading(false)
                         });
                    }}>
                    {({
                        values,
                        handleChange,
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <form onSubmit={handleSubmit} className='text-custom-blue flex flex-col justify-center items-center gap-8 mt-6 w-full'>
                            <div className='flex lg:flex-row flex-col gap-5 w-full'>
                                <div className="flex flex-col gap-2">
                                    <span className="p-float-label">
                                        <Field as={InputText} id="title" name="title" value={values?.title} onChange={handleChange} />
                                        <label htmlFor="title">Title</label>
                                    </span>
                                    <ErrorMessage name="title" component="div" className="text-red-500" />
                                </div>
                                <div className="flex flex-col gap-2">
                                    <span className="p-float-label">
                                        <Field as={InputText} id="description" name="description" value={values?.description} onChange={handleChange} />
                                        <label htmlFor="description">Description</label>
                                    </span>
                                    <ErrorMessage name="description" component="div" className="text-red-500" />
                                </div>
                                <div className="flex flex-col gap-2">
                                    <span className="p-float-label">
                                        <Field as={InputText} id="keyPoints" name="keyPoints" value={values?.keyPoints} onChange={handleChange} />
                                        <label htmlFor="keyPoints">Key Points</label>
                                    </span>
                                    <ErrorMessage name="keyPoints" component="div" className="text-red-500" />
                                </div>
                            </div>
                            <div className='flex lg:flex-row flex-col gap-5 w-full'>
                                <div className="flex flex-col gap-2">
                                    <span className="p-float-label">
                                        <Field as={Calendar} showTime={true} hourFormat="12" id="startDate" label={"startDate"} name="startDate" value={values?.startDate} onChange={handleChange} />
                                        <label htmlFor="startDate">Start Date</label>
                                    </span>
                                    <ErrorMessage name="startdate" component="div" className="text-red-500" />
                                </div>
                                <div className="flex flex-col gap-2">
                                    <span className="p-float-label">
                                        <Field as={Calendar} showTime={true} hourFormat="12" id="endDate" label={"endDate"} name="endDate" value={values?.endDate} onChange={handleChange} />
                                        <label htmlFor="endDate">End Date</label>
                                    </span>
                                    <ErrorMessage name="enddate" component="div" className="text-red-500" />
                                </div>
                                <div className="flex flex-col gap-2">
                                    <span className="p-float-label">
                                        <Field as={InputText} id="location" label={"location"} name="location" value={values?.location} onChange={handleChange} />
                                        <label htmlFor="location">Location</label>
                                    </span>
                                    <ErrorMessage name="location" component="div" className="text-red-500" />
                                </div>
                            </div>
                            <div className='flex lg:flex-row flex-col gap-5 w-full justify-between'>
                                <div className="flex flex-col gap-2 w-full">
                                    <Field
                                        as={Dropdown}
                                        id="organizerUuid"
                                        name="organizerUuid"
                                        value={values.organizerUuid}
                                        options={merchantOptions || null}
                                        optionLabel="name"
                                        optionValue="code"
                                        className='max-w-52'
                                        onChange={handleChange}
                                        placeholder="Select an Organizer"
                                        filter
                                    />
                                    <ErrorMessage name="roleUuid" component="div" className="text-red-500" />
                                </div>
                            <div className="flex flex-col gap-2 w-full">
                                <Field
                                    as={MultiSelect}
                                    id="merchantUuids"
                                    name="merchantUuids"
                                    value={values.merchantUuids}
                                    options={merchantOptions || null}
                                    optionLabel="name"
                                    optionValue="code"
                                    className='max-w-52'
                                    onChange={handleChange}
                                    placeholder="Select merchants"
                                    showClear
                                    filter
                                />
                                <ErrorMessage name="roleUuid" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-col gap-2 w-full">
                                <Field
                                    as={Dropdown}
                                    id="interestUuid"
                                    name="interestUuid"
                                    value={values.interestUuid}
                                    options={interestOptions || null}
                                    optionLabel="name"
                                    optionValue="code"
                                    onChange={handleChange}
                                    className='max-w-52'
                                    placeholder="Select an Interest"
                                    filter
                                />
                                <ErrorMessage name="interestUuid" component="div" className="text-red-500" />
                            </div>
                        </div>
                        <div className='flex lg:flex-row flex-col w-full justfiy-between items-center'>
                            <div className="flex flex-col gap-2 w-full">
                                <Field
                                    as={MultiSelect}
                                    id="groupUuids"
                                    name="groupUuids"
                                    value={values.groupUuids}
                                    options={groups || null}
                                    optionLabel="name"
                                    optionValue="code"
                                    className='max-w-52'
                                    onChange={handleChange}
                                    placeholder="Select groups"
                                    showClear
                                    filter
                                />
                                <ErrorMessage name="roleUuid" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-row gap-1 ml-2 w-full">
                                <Checkbox inputId="allDay" name="allDay" variant='filled' value="allDay" className='border border-custom-blue rounded-lg solid' onChange={handleChange} checked={values.allDay} />
                                <label htmlFor="allDay">All Day</label>
                            </div>
                            <div className="flex flex-row gap-1 w-full">
                                <Checkbox inputId="isActive" name="isActive" variant='filled' value="isActive" className='border border-custom-blue rounded-lg solid' onChange={handleChange} checked={values.isActive} />
                                <label htmlFor="isActive">Active</label>
                            </div>
                            <div className="flex flex-row gap-1 w-full">
                                <Checkbox inputId="showMembers" name="showMembers" variant='filled' value="showMembers" className='border border-custom-blue rounded-lg solid' onChange={handleChange} checked={values.showMembers} />
                                <label htmlFor="showMembers">Show Members</label>
                            </div>
                        </div>
                            <Button
                                type="submit"
                                label={isSubmitting ? 'Updating...' : 'Update'}
                                className="bg-custom-blue text-white hover:bg-light-blue-2 hover:text-custom-blue border-custom-blue w-full"
                                disabled={isSubmitting}
                            />
                        </form>
                     )}
            </Formik>
        </Dialog>
    )
}

export default EditEvent