import React from 'react'

const NotFound = () => {
    return (
        <div className='text-center' style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <h2 className='text-8xl font-bold'>404</h2>
            <h3 className='font-semibold text-xl'>Not Found</h3>
            <p className='font-semibold text-lg'>The page you are looking for doesn't exist.</p>
            <a href='/' className='underline font-semibold'>Back to home</a>
        </div>
    )
}

export default NotFound