import { PrivateApiCall } from "../config/interceptors";
import { errorCatch } from "../helpers/error";

async function GetEventsStatus() {
    const apiCall = await PrivateApiCall.get("/admin/dashboard/eventsStatus")
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error getting events"));
  return apiCall;
}

async function GetNextEvents() {
  const apiCall = await PrivateApiCall.get("/admin/dashboard/nextevents")
  .then((response) => response)
  .catch((error) => errorCatch(error, "Error getting next events"));
return apiCall;
}

// topoffers
async function GetTopOffers() {
  const apiCall = await PrivateApiCall.get("/admin/dashboard/topoffers")
  .then((response) => response)
  .catch((error) => errorCatch(error, "Error getting top offers"));
return apiCall;
}

export {
    GetEventsStatus,
    GetNextEvents,
    GetTopOffers
}