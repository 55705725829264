import { PrivateApiCall } from "../config/interceptors";
import { errorCatch } from "../helpers/error";

async function GetUsers() {
    const apiCall = await PrivateApiCall.get("/admin/users")
      .then((response) => response)
      .catch((error) => errorCatch(error, "Error getting users"));
    return apiCall;
  }

async function CreateUser(data) {
    const apiCall = await PrivateApiCall.post("/admin/users/create", data)
      .then((response) => response)
      .catch((error) => errorCatch(error, "Error creating user"));
    return apiCall;
}

async function DeleteUser(data){
    const apiCall = await PrivateApiCall.delete("/admin/users/delete", {data: data})
      .then((response) => response)
      .catch((error) => errorCatch(error, "Error deleting user"));
    return apiCall;
}

async function UpdateUser(data, id){
    const apiCall = await PrivateApiCall.put(`/admin/users/update/${id}`, data)
      .then((response) => response)
      .catch((error) => errorCatch(error, "Error updating user"));
    return apiCall;
}

async function ActivateUser(id, data){
  const apiCall = await PrivateApiCall.put(`/admin/users/activate/${id}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error activating user"));
  return apiCall;
}

async function VerifyUser(id, data){
  const apiCall = await PrivateApiCall.put(`/admin/users/verify/${id}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error verifying user"));
  return apiCall;
}

export  {
    GetUsers,
    CreateUser,
    DeleteUser,
    UpdateUser,
    ActivateUser,
    VerifyUser
}