import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { showToast } from '../../App'
import { ErrorMessage, Field, Formik } from 'formik'
import { ResetPassword } from '../../network/Auth.network'
import { Password } from 'primereact/password'
import { Button } from 'primereact/button'
import Required from '../../components/Required/Required'
import { jwtDecode } from 'jwt-decode'
import LoginLayout from '../../layout/LoginLayout'

const ResetPass = () => {
    const navigate = useNavigate()
    const url = window.location.href;
    const resetToken = url.split('/').pop();

    useEffect(() => {
        try {
            if (resetToken) {
                const decoded = jwtDecode(resetToken);
                const currentTime = Date.now() / 1000; // Get current time in seconds
                // Check if token is expired
                if (!decoded?.uuid || decoded.exp < currentTime) {
                    navigate('/login');
                }
            } else {
                navigate('/login');
            }
        } catch (error) {
            navigate('/login');
        }
    }, [navigate, resetToken]);

    return (
        <LoginLayout title="Reset Password">
            <Formik
                initialValues={{
                    password: '',
                    confirmPass: '',
                }}

                validate={values => {
                    const errors = {};
                    if (!values.password) {
                        errors.password = 'New password is required.';
                    } else if (
                        !/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/i.test(values.password)
                    ) {
                        errors.password = 'Password must be strong';
                    }
                    
                    if (!values.confirmPass) {
                        errors.confirmPass = 'Confirm password is required.';
                    }
                    if(values.password !== values.confirmPass){
                        errors.confirmPass = 'Passwords does not match'
                    }

                    return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true)
                    const decoded = jwtDecode(resetToken);
                    values.email = decoded?.email
                    await ResetPassword(values, resetToken).then(response => {
                        if (response?.status === 200) {
                            showToast('success', 'Password changed successfully.')
                            navigate('/login')
                        } else {
                            return showToast('error', response?.data?.error ? response?.data?.error : 'Error resetting password.')
                        }
                    }).catch(error => {
                        console.error('Error submitting form:', error);
                        showToast('error', 'An error occurred while submitting the form.');
                    })
                        .finally(() => {
                            setSubmitting(false)
                        });
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit} className='text-custom-blue flex flex-col justify-center items-center gap-6 w-full'>
                        <div className='flex flex-col gap-8 w-full'>
                            <div className="flex flex-col gap-2 w-full">
                                <span className="p-float-label w-full">
                                    <Field as={Password} id="password" name="password" className="w-full" />
                                    <label htmlFor="password">New Password<Required /></label>
                                </span>
                                <ErrorMessage name="password" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-col gap-2 w-full">
                                <span className="p-float-label w-full">
                                    <Field as={Password} id="confirmPass" name="confirmPass" className="w-full" />
                                    <label htmlFor="confirmPass">Confirm Password<Required /></label>
                                </span>
                                <ErrorMessage name="confirmPass" component="div" className="text-red-500" />
                            </div>
                        </div>
                        <Button
                            type="submit"
                            label={isSubmitting ? 'Submitting...' : 'Submit'}
                            className="bg-custom-blue text-white hover:bg-light-blue-2 hover:text-custom-blue border-custom-blue w-full"
                            disabled={isSubmitting}
                        />
                    </form>
                )}
            </Formik>
        </LoginLayout>
    )
}

export default ResetPass