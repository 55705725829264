import { ErrorMessage, Field, Formik } from 'formik';
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react'
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { showToast } from '../../App';
import { GetRoles } from '../../network/Roles.network';
import { CreateAdmin } from '../../network/Admins.network';

const AddAdmin = (props) => {
    const [roles, setRoles] = useState([])

    const header = (
        <h1 className='text-center text-2xl text-custom-blue'>Add Admin</h1>
    )

    useEffect(() => {
        const fetchRoles = async () => {
            await GetRoles().then(response => {
                if (response?.status === 200) {
                    setRoles(response.data.data?.map(role => (
                        {
                            name: role?.name,
                            value: role?.uuid
                        }
                    )))
                }
            })
        }
        fetchRoles()
    }, [])

    return (
        <Dialog
            header={header}
            visible={props.visible}
            onHide={() => props.setVisible(false)}
            className='w-auto'
            >
            <Formik
                initialValues={{ firstName: '', lastName: '', email: '', phone: '', password: '', roleUuid: '' }}
                validate={values => {
                    const errors = {};
                    if (!values.firstName) {
                        errors.firstName = 'First name is required.';
                    }
                    if (!values.lastName) {
                        errors.lastName = 'Last name is required.';
                    }
        
                    if (!values.password) {
                        errors.password = 'Password is required.';
                    }else if (
                        !/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(values.password)
                    ){
                        errors.password = 'Password must be strong.';
                    }
        
                    if (!values.phone) {
                        errors.phone = 'Phone is required.';
                    }
                    if (!values.roleUuid) {
                        errors.roleUuid = 'Role is required.';
                    }
                    if (!values.email) {
                        errors.email = 'Email is required';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = 'Invalid email address';
                    }
                    return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                        await CreateAdmin(values).then(response => {
                            if (response?.status === 200) {
                                props.setAdmins(prevUsers => [...prevUsers, response?.data])
                                props.setTotalRecords(prev => prev + 1)
                                showToast('success', 'User created successfully!')
                                props.setVisible(false)
                            }else{
                                return showToast('error', response?.data?.error ? response?.data?.error : 'Error creating user.')
                            }

                        }).catch(error => {
                            console.error('Error submitting form:', error);
                            showToast('error', 'An error occurred while submitting the form.');
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <form onSubmit={handleSubmit} className='text-custom-blue flex flex-col justify-center items-center gap-8 mt-6 w-full'>
                        <div className='flex lg:flex-row flex-col gap-5'>
                            <div className="flex flex-col gap-2">
                                <span className="p-float-label">
                                    <Field as={InputText} id="firstName" name="firstName" />
                                    <label htmlFor="firstName">First name</label>
                                </span>
                                <ErrorMessage name="firstName" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <span className="p-float-label">
                                    <Field as={InputText} id="lastName" name="lastName" />
                                    <label htmlFor="lastName">Last name</label>
                                </span>
                                <ErrorMessage name="lastName" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <span className="p-float-label">
                                    <Field as={InputText} id="email" name="email" />
                                    <label htmlFor="email">Email</label>
                                </span>
                                <ErrorMessage name="email" component="div" className="text-red-500" />
                            </div>
                        </div>
                        <div className='flex lg:flex-row flex-col gap-5 items-center'>
                            <div className="flex flex-col gap-2">
                                <span className="p-float-label">
                                    <Field as={InputText} id="password" label={"Password"} name="password" type={"password"} />
                                    <label htmlFor="password">Password</label>
                                </span>
                                <ErrorMessage name="password" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <span className="p-float-label">
                                    <Field as={InputText} id="phone" label={"phone"} name="phone" />
                                    <label htmlFor="phone">Phone</label>
                                </span>
                                <ErrorMessage name="phone" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <Field
                                    as={Dropdown}
                                    id="roleUuid"
                                    name="roleUuid"
                                    value={values.roleUuid}  // Ensure this is correctly bound
                                    options={roles || null}
                                    optionLabel="name"
                                    onChange={handleChange}
                                    placeholder="Select a Role"
                                />
                                <ErrorMessage name="roleUuid" component="div" className="text-red-500" />
                            </div>
                        </div>
                        <Button
                            type="submit"
                            label={isSubmitting ? 'Submitting...' : 'Submit'}
                            // icon="pi pi-user-plus"
                            className="bg-custom-blue text-white hover:bg-light-blue-2 hover:text-custom-blue border-custom-blue w-full"
                            disabled={isSubmitting} />
                    </form>
                )}
            </Formik>
        </Dialog>
    )
}

export default AddAdmin