import { ErrorMessage, Field, Formik } from 'formik';
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext';
import React, { useState, useEffect, useRef } from 'react'
import { Button } from 'primereact/button';
import { showToast } from '../../App';
import { ProgressSpinner } from 'primereact/progressspinner';
import { CreateEvent } from '../../network/Events.network';
import { Calendar } from 'primereact/calendar';
import { GetInterests } from '../../network/Interests.network';
import { GetMerchants } from '../../network/Merchants.network';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import Required from '../../components/Required/Required';
import { GetGroups } from '../../network/Groups.network';

const AddEvent = (props) => {
    const [loading, setLoading] = useState(false)
    const [merchantOptions, setMerchantOptions] = useState([])
    const [interestOptions, setInterestOptions] = useState([])
    const [groups, setGroups] = useState([])


    const header = (
        <h1 className='text-center text-2xl text-custom-blue'>Add Event</h1>
    )

    const getMerchants = async () => {
        try {
            setLoading(true)
            await GetMerchants().then(response => {
                if (response?.status === 200) {
                    setMerchantOptions(response.data.data?.map(role => (
                        {
                            name: role?.merchantName,
                            code: role?.uuid
                        }
                    )))
                }
            })
        } catch (error) {
            showToast('error', 'Error fetching Merchants.')
        } finally {
            setLoading(false)
        }
    }

    const getGroups = async () => {
        try {
            setLoading(true)
            await GetGroups().then(response => {
                if (response?.status === 200) {
                    setGroups(response.data.data?.map(group => (
                        {
                            name: group?.title,
                            code: group?.uuid
                        }
                    )))
                }
            })
        } catch (error) {
            console.error('Error getting groups: ', error)
            showToast('error', 'Error fetching Groups.')
        } finally {
            setLoading(false)
        }
    }

    const getInterests = async () => {
        try {
            setLoading(true)
            await GetInterests().then(response => {
                if (response?.status === 200) {
                    setInterestOptions(response.data.data?.map(role => (
                        {
                            name: role?.interestName,
                            code: role?.uuid
                        }
                    )))
                }
            })
        } catch (error) {
            showToast('error', 'Error fetching interests.')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (props.visible) {
            getMerchants()
            getInterests()
            getGroups()
        }
    }, [props.visible])

    return (
        <Dialog
            header={header}
            visible={props.visible}
            onHide={() => props.setVisible(false)}
            className='w-auto'
        >
            <Formik
                initialValues={{
                    title: '',
                    description: '',
                    startDate: new Date(),
                    endDate: new Date(),
                    allDay: false,
                    isActive: false,
                    location: '',
                    interestUuid: '',
                    merchantUuids: [],
                    keyPoints: '',
                    organizerUuid: '',
                    groupUuids: [],
                    showMembers: false
                }}

                validate={values => {
                    const errors = {};
                    if (!values.title) {
                        errors.title = 'Title is required.';
                    }
                    if (!values.startDate) {
                        errors.startDate = 'Start date is required.';
                    }
                    if (!values.endDate) {
                        errors.endDate = 'End date is required.';
                    }
                    if (!values.interestUuid) {
                        errors.interestUuid = 'Interest is required.';
                    }
                    if (!values.organizerUuid) {
                        errors.organizerUuid = 'Organizer is required.';
                    }
                    if (values?.keyPoints) {
                        const keyPointsArray = values?.keyPoints?.split(',').map(item => item.trim());
                        const invalidKeyPoint = keyPointsArray?.some(point => point?.length === 0);
                        if (invalidKeyPoint) {
                            errors.keyPoints = 'Invalid format';
                        }
                    }
                    return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true)
                    await CreateEvent(values).then(response => {
                        if (response?.status === 200) {
                            const newEvent = response?.data?.data
                            props.setEvents(prevEvents => [...prevEvents, newEvent])
                            props.setTotalRecords(prevTotalRecords => prevTotalRecords + 1)
                            showToast('success', 'Event created successfully.')
                            props.setVisible(false)
                        } else {
                            return showToast('error', response?.data?.error ? response?.data?.error : 'Error creating event.')
                        }
                    }).catch(error => {
                        showToast('error', 'An error occurred while submitting the form.');
                    })
                        .finally(() => {
                            setSubmitting(false)
                        });
                }}
            >
                {({
                    values,
                    handleChange,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit} className='text-custom-blue flex flex-col justify-center items-center gap-8 mt-6 w-full'>
                        <div className='flex lg:flex-row flex-col gap-5 w-full'>
                            <div className="flex flex-col gap-2">
                                <span className="p-float-label">
                                    <Field as={InputText} id="title" name="title" value={values?.title} onChange={handleChange} />
                                    <label htmlFor="title">Title <Required /></label>
                                </span>
                                <ErrorMessage name="title" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <span className="p-float-label">
                                    <Field as={InputText} id="description" name="description" value={values?.description} onChange={handleChange} />
                                    <label htmlFor="description">Description</label>
                                </span>
                                <ErrorMessage name="description" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <span className="p-float-label">
                                    <Field as={InputText} id="keyPoints" name="keyPoints" value={values?.keyPoints} onChange={handleChange} />
                                    <label htmlFor="keyPoints">Key Points</label>
                                </span>
                                <ErrorMessage name="keyPoints" component="div" className="text-red-500" />
                            </div>
                        </div>
                        <div className='flex lg:flex-row flex-col gap-5 w-full'>
                            <div className="flex flex-col gap-2 w-full">
                                <span className="p-float-label">
                                    <Field as={Calendar} showTime={true} hourFormat="12" id="startDate" label="startDate" name="startDate" value={values?.startDate} onChange={handleChange} />
                                    <label htmlFor="startDate">Start Date <Required /></label>
                                </span>
                                <ErrorMessage name="startdate" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-col gap-2 w-full">
                                <span className="p-float-label">
                                    <Field as={Calendar} showTime={true} hourFormat="12" id="endDate" label={"endDate"} name="endDate" value={values?.endDate} onChange={handleChange} />
                                    <label htmlFor="endDate">End Date <Required /></label>
                                </span>
                                <ErrorMessage name="enddate" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <span className="p-float-label">
                                    <Field as={InputText} id="location" label={"location"} name="location" value={values?.location} onChange={handleChange} />
                                    <label htmlFor="location">Location</label>
                                </span>
                                <ErrorMessage name="location" component="div" className="text-red-500" />
                            </div>
                        </div>
                        <div className='flex lg:flex-row flex-col gap-5 w-full justify-between'>
                            <div className="flex flex-col gap-2 w-full">
                                <Field
                                    as={Dropdown}
                                    id="organizerUuid"
                                    name="organizerUuid"
                                    value={values.organizerUuid}
                                    options={merchantOptions || null}
                                    optionLabel="name"
                                    optionValue="code"
                                    className='max-w-52'
                                    onChange={handleChange}
                                    placeholder="Select an Organizer"
                                    filter
                                />
                                <ErrorMessage name="roleUuid" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-col gap-2 w-full">
                                <Field
                                    as={MultiSelect}
                                    id="merchantUuids"
                                    name="merchantUuids"
                                    value={values.merchantUuids}
                                    options={merchantOptions || null}
                                    optionLabel="name"
                                    optionValue="code"
                                    className='max-w-52'
                                    onChange={handleChange}
                                    placeholder="Select merchants"
                                    filter
                                    showClear
                                />
                                <ErrorMessage name="roleUuid" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-col gap-2 w-full">
                                <Field
                                    as={Dropdown}
                                    id="interestUuid"
                                    name="interestUuid"
                                    value={values.interestUuid}
                                    options={interestOptions || null}
                                    optionLabel="name"
                                    optionValue="code"
                                    onChange={handleChange}
                                    className='max-w-52'
                                    placeholder="Select an Interest"
                                    filter
                                />
                                <ErrorMessage name="interestUuid" component="div" className="text-red-500" />
                            </div>
                        </div>
                        <div className='flex lg:flex-row flex-col w-full justfiy-between items-center'>
                            <div className="flex flex-col gap-2 w-full">
                                <Field
                                    as={MultiSelect}
                                    id="groupUuids"
                                    name="groupUuids"
                                    value={values.groupUuids}
                                    options={groups || null}
                                    optionLabel="name"
                                    optionValue="code"
                                    className='max-w-52'
                                    onChange={handleChange}
                                    filter
                                    showClear
                                    placeholder="Select groups"
                                />
                                <ErrorMessage name="roleUuid" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-row gap-1 w-full ml-2">
                                <Checkbox inputId="allDay" name="allDay" variant='filled' value="allDay" className='border border-custom-blue rounded-lg solid' onChange={handleChange} checked={values.allDay} />
                                <label htmlFor="allDay">All Day</label>
                            </div>
                            <div className="flex flex-row gap-1 w-full">
                                <Checkbox inputId="isActive" name="isActive" variant='filled' value="isActive" className='border border-custom-blue rounded-lg solid' onChange={handleChange} checked={values.isActive} />
                                <label htmlFor="isActive">Active</label>
                            </div>
                            <div className="flex flex-row gap-1 w-full">
                                <Checkbox inputId="showMembers" name="showMembers" variant='filled' value="showMembers" className='border border-custom-blue rounded-lg solid' onChange={handleChange} checked={values.showMembers} />
                                <label htmlFor="showMembers">Show Members</label>
                            </div>
                        </div>
                        {/* <div  className='flex lg:flex-row flex-col gap-5 w-full justfiy-between items-center'>
                            <div className="flex flex-col gap-2">
                                <span>
                                    <input type="file" onChange={handleImageChange} />
                                </span>
                                {selectedImage && (
                                    <img src={selectedImage} alt="Selected" className="mt-2" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                                )}
                                <ErrorMessage name="address" component="div" className="text-red-500" />
                            </div>
                        </div> */}
                        <Button
                            type="submit"
                            label={isSubmitting ? 'Submitting...' : 'Submit'}
                            className="bg-custom-blue text-white hover:bg-light-blue-2 hover:text-custom-blue border-custom-blue w-full"
                            disabled={isSubmitting}
                        />
                    </form>
                )}
            </Formik>
        </Dialog>
    )
}

export default AddEvent