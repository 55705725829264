import { HashRouter, Navigate, Route, Routes, useParams } from 'react-router-dom';
import './App.css';
import { Suspense, useEffect, useRef, useState } from 'react';
import Login from './pages/login';
import Layout from './layout/layout';
import 'primereact/resources/themes/saga-blue/theme.css'; // Choose your desired theme
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import secureLocalStorage from 'react-secure-storage';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { jwtDecode } from 'jwt-decode';
import ResetPass from './pages/ResetPass/ResetPass';
import Loading from './components/Loading/Loading';

let toast;
export const showToast = (type, message) => {
  toast.current.show({ severity: type, summary: type.charAt(0).toUpperCase() + type.slice(1), detail: message, life: 3000 });
};

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  toast = useRef(null)
  // const { savePermissions } = usePermissions();

  useEffect(() => {
    const token = secureLocalStorage.getItem('token');
    if (token) {
      onLoad();
    } else {
      setLoading(false);
    }

    async function onLoad() {
      const decoded = jwtDecode(token);
      if (decoded?.exp * 1000 > Date.now()) {
        // const permissions = secureLocalStorage.getItem('permissions');
        // if (permissions) {
        // savePermissions(permissions);
        setLoggedIn(true);
        // }
      } else {
        setLoggedIn(false);
        secureLocalStorage.clear()
      }
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <HashRouter>
      <Toast ref={toast} />
      <Suspense fallback={<ProgressSpinner style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />}>
        <Routes>
          <Route path="/login" element={<Login setIsLoggedin={setLoggedIn} />} />
          <Route path="/resetpassword/:token" element={<ResetPass loggedIn={loggedIn} />} />
          <Route path="*" element={loggedIn ? <Layout /> : <Navigate to="/login" />} />
        </Routes>
      </Suspense>
    </HashRouter>
  );
}

export default App;
