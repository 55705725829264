import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../layout/DashboardLayout'
import { GetEventsStatus } from '../../network/Dashboard.network'

const EventsStatusAnalytics = () => {
    const [status, setStatus] = useState({})
    const [loading, setLoading] = useState(false)

    const fetchEventsStatus = async () => {
        try {
            setLoading(true)
            await GetEventsStatus().then(response => {
                if(response?.status === 200){
                    setStatus(response?.data?.data)
                }else{
                    throw new Error('Error getting status')
                }
            })
        } catch (error){
            console.error('Error fetching events status: ', error)
        } finally{
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchEventsStatus()   
    },[])

    return (
        <DashboardLayout title='Events Status' handleClick={fetchEventsStatus} loading={loading}>
            <div className='flex flex-col h-96 w-full justify-between py-12 px-12 text-center'>
                <div className='flex lg:flex-row flex-col justify-between w-full'>
                    <div className='flex flex-col gap-2 w-full'>
                        <h2 className='font-semibold text-2xl text-blue-950'>Total</h2>
                        <p className='font-semibold'>{status?.total}</p>
                    </div>
                    <div className='flex flex-col gap-2 w-full'>
                        <h2 className='font-semibold text-red-950 text-2xl'>Deleted</h2>
                        <p className='font-semibold'>{status?.deleted}</p>
                    </div>
                </div>
                <div className='flex lg:flex-row flex-col justify-between w-full'>
                    <div className='flex flex-col gap-2 w-full'>
                        <h2 className='font-semibold text-2xl text-green-500'>Active</h2>
                        <p className='font-semibold'>{status?.active}</p>
                    </div>
                    <div className='flex flex-col gap-2 w-full'>
                        <h2 className='font-semibold text-2xl text-red-700'>Inactive</h2>
                        <p className='font-semibold'>{status?.inactive}</p>
                    </div>
                </div>
                <div className='flex lg:flex-row flex-col justify-between w-full'>
                    <div className='flex flex-col gap-2 w-full'>
                        <h2 className='font-semibold text-2xl text-blue-500'>Published</h2>
                        <p className='font-semibold'>{status?.published}</p>
                    </div>
                    <div className='flex  flex-col gap-2 w-full'>
                        <h2 className='font-semibold text-2xl text-red-500'>Unpublished</h2>
                        <p className='font-semibold'>{status?.unpublished}</p>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default EventsStatusAnalytics