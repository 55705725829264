import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react'
import { showToast } from '../../App';
import { ForgotPasswordd } from '../../network/Auth.network';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = (props) => {
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)

    const handleForgot = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            if(!email || email === ''){
                return showToast('warn', 'Email is required!')
            }
            await ForgotPasswordd({ email: email }).then(response => {
                if (response?.status === 200) {
                    showToast('success', 'Please check your email to reset your password')
                    
                    props.setShowForgotPass(false)
                } else {
                    return showToast('error', 'Error submitting. Please check your email!')
                }
            })
        } catch (error) {
            console.log('FORGOT PASS ERROR: ' + error);
            throw error
        } finally {
            setLoading(false)
        }
    };

    return (
        <form onSubmit={handleForgot} className=" w-full px-5 gap-2 flex flex-col">
            <span className="p-float-label">
                <InputText
                    label={"Email address"}
                    id={"email"}
                    type={"text"}
                    className='w-full border-custom-blue'
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="email"
                    required
                />
                <label htmlFor="email" className='px-2'>Email</label>
            </span>
            <div className="text-end flex flex-row items-center gap-1 justify-end">
                <i className="pi pi-arrow-circle-left" /> 
                <div className="font-semibold block text-sm leading-6 text-custom-blue underline cursor-pointer" onClick={() => props.setShowForgotPass(false)}>Back To Login</div>
            </div>
            <div className='text-center'>
                <Button type='submit' variant="contained" className='w-full bg-custom-blue justify-center'>{loading ? 'Submitting...' : 'Submit'}</Button>
            </div>
        </form>
    )
}

export default ForgotPassword