import { PrivateApiCall } from "../config/interceptors";
import { errorCatch } from "../helpers/error";

async function GetMerchants() {
    const apiCall = await PrivateApiCall.get("/admin/merchant")
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error getting merchants"));
  return apiCall;
}

async function CreateMerchant(data) {
  const apiCall = await PrivateApiCall.post("/admin/merchant/create", data)
  .then((response) => response)
  .catch((error) => errorCatch(error, "Error creating merchant"));
return apiCall;
}

async function UpdateMerchant(data, id){
  const apiCall = await PrivateApiCall.put(`/admin/merchant/update/${id}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error updating user"));
  return apiCall;
}

async function ActivateMerchant(id, data){
  const apiCall = await PrivateApiCall.put(`/admin/merchant/activate/${id}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error updating merchant"));
  return apiCall;
}

async function VerifyMerchant(id, data){
  const apiCall = await PrivateApiCall.put(`/admin/merchant/verify/${id}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error updating merchant"));
  return apiCall;
}

async function RemoveMerchant(data){
  const apiCall = await PrivateApiCall.delete("/admin/merchant/delete", {data: data})
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error deleting merchant"));
  return apiCall;
}

async function AddMerchantImage(data, id){
  const apiCall = await PrivateApiCall.put(`/admin/merchant/add-image/${id}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error uploading image"));
  return apiCall;
}

export  {
  GetMerchants,
  CreateMerchant,
  RemoveMerchant,
  UpdateMerchant,
  ActivateMerchant,
  VerifyMerchant,
  AddMerchantImage
}