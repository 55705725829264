import React from 'react'
import EventsAnalytics from '../../components/EventsAnalytics/EventsAnalytics';
import OffersAnalytics from '../../components/OffersAnalytics/OffersAnalytics';
import GroupsAnalytics from '../../components/GroupsAnalytics/GroupsAnalytics';
import EventsStatusAnalytics from '../../components/EventsAnalytics/EventsStatusAnalytics';

const Dashboard = () => {

    return (
        <div className='flex flex-col gap-4 w-full px-4 py-4'>
            <div className='flex lg:flex-row flex-col justify-between gap-4 w-full'>
                <OffersAnalytics />
                <EventsStatusAnalytics />
            </div>
            <div className='flex lg:flex-row flex-col justify-between gap-4 w-full'>
                <EventsAnalytics />
                {/* <GroupsAnalytics/> */}
            </div>
        </div>
    )
}

export default Dashboard