import { PrivateApiCall } from "../config/interceptors";
import { errorCatch } from "../helpers/error";

async function GetOffers() {
    const apiCall = await PrivateApiCall.get("/admin/offer")
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error getting offers"));
  return apiCall;
}

async function CreateOffer(data) {
  const apiCall = await PrivateApiCall.post("/admin/offer/create", data)
  .then((response) => response)
  .catch((error) => errorCatch(error, "Error creating offer"));
return apiCall;
}

async function UpdateOffer(data, id){
  const apiCall = await PrivateApiCall.put(`/admin/offer/update/${id}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error updating offer"));
  return apiCall;
}

async function DeleteOffer(data){
  const apiCall = await PrivateApiCall.delete("/admin/offer/delete", {data: data})
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error deleting offer"));
  return apiCall;
}

async function ActivateOffer(id, data){
  const apiCall = await PrivateApiCall.put(`/admin/offer/activate/${id}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error updating offer"));
  return apiCall;
}

async function PublishOffer(id, data){
  const apiCall = await PrivateApiCall.put(`/admin/offer/publish/${id}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error updating offer"));
  return apiCall;
}

export  {
  GetOffers,
  CreateOffer,
  DeleteOffer,
  UpdateOffer,
  ActivateOffer,
  PublishOffer
}