import { ErrorMessage, Field, Formik } from 'formik';
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react'
import { Button } from 'primereact/button';
import { showToast } from '../../App';
import "../../styles.css"
import { UpdateInterest } from '../../network/Interests.network';

const EditInterest = (props) => {
    const [selectedImage, setSelectedImage] = useState(null)
    const [loading, setLoading] = useState(false)
    const [image, setImage] = useState(null)

    const header = (
        <h1 className='text-center text-2xl text-custom-blue'>Edit Interest</h1>
    )

    useEffect(() => {
        if (props.interest.image) {
            setImage(props.interest.image)
            setSelectedImage(props.interest.image)
        }
    }, [props])

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImage(file)
            setSelectedImage(URL.createObjectURL(file));
        }
    };

    return (
        <Dialog
            header={header}
            visible={props.visible}
            onHide={() => props.setVisible(false)}
            className='w-auto'
        >
            <Formik
                initialValues={{
                    interestName: props?.interest?.interestName,
                    description: props?.interest?.description,
                }}
                validate={values => {
                    const errors = {};
                    if (!values.interestName) {
                        errors.interestName = 'Interest name is required.';
                    }
                    return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true)
                    const formData = new FormData()
                    if (image) {
                        formData.append('image', image)
                    }
                    Object.keys(values).forEach(key => {
                        formData.append(key, values[key]);
                    });
                    await UpdateInterest(formData, props.interest.uuid).then(response => {
                        if (response?.status === 200) {
                            props.setInterests(prev => {
                                return prev.map(interest => {
                                    if (interest.uuid === props.interest.uuid) {
                                        return response?.data?.data;
                                    } else {
                                        return interest;
                                    }
                                });
                            });
                            showToast('success', 'Interest updated successfully!')
                            props.setVisible(false)
                        } else {
                            return showToast('error', response?.data?.error ? response?.data?.error : 'Error updating interest.')
                        }

                    }).catch(error => {
                        console.error('Error submitting form:', error);
                        showToast('error', 'An error occurred while submitting the form.');
                    })
                        .finally(() => {
                            setLoading(false)
                        });
                }}
            >
                {({
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <form onSubmit={handleSubmit} className='text-custom-blue flex flex-col justify-center items-center gap-5 mt-5 w-full'>
                        <div className='flex lg:flex-row flex-col justify-between w-full gap-4'>
                            <div className="flex flex-col gap-2">
                                <span className="p-float-label">
                                    <Field as={InputText} id="interestName" name="interestName" />
                                    <label htmlFor="interestName">Interest</label>
                                </span>
                                <ErrorMessage name="interestName" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <span className="p-float-label">
                                    <Field as={InputText} id="description" name="description" />
                                    <label htmlFor="description">Description</label>
                                </span>
                                <ErrorMessage name="description" component="div" className="text-red-500" />
                            </div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <span>
                                <input type="file" onChange={handleImageChange} />
                            </span>
                            {selectedImage && (
                                <img src={selectedImage} alt="Selected" className="mt-2" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                            )}
                            <ErrorMessage name="address" component="div" className="text-red-500" />
                        </div>
                        <Button
                            type="submit"
                            label={isSubmitting ? 'Updating...' : 'Update'}
                            className="bg-custom-blue text-white hover:bg-light-blue-2 hover:text-custom-blue border-custom-blue w-full"
                            disabled={isSubmitting}
                        />
                    </form>
                )}
            </Formik>
        </Dialog>
    )
}

export default EditInterest