import { PrivateApiCall } from "../config/interceptors";
import { errorCatch } from "../helpers/error";

async function GetRoles() {
    const apiCall = await PrivateApiCall.get("/admin/roles")
      .then((response) => response)
      .catch((error) => errorCatch(error, "Error getting roles"));
    return apiCall;
  }

  async function DeleteRole(data) {
    const apiCall = await PrivateApiCall.delete("/admin/roles/delete", data)
      .then((response) => response)
      .catch((error) => errorCatch(error, "Error deleting roles"));
    return apiCall;
  }


  export {
    GetRoles,
    DeleteRole
  }