import { ErrorMessage, Field, Formik } from 'formik';
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext';
import React, { useState, useEffect } from 'react'
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { showToast } from '../../App';
import { CreateMerchant } from '../../network/Merchants.network';
import { GetInterests } from '../../network/Interests.network';
import { Calendar } from 'primereact/calendar';
import "../../styles.css"
import { Dropdown } from 'primereact/dropdown';
import { types } from '../../constants/merchantsTypes';
import Required from '../../components/Required/Required';

const AddMerchant = (props) => {
    const [interests, setInterests] = useState([])
    const [loading, setLoading] = useState(false)


    const header = (
        <h1 className='text-center text-2xl text-custom-blue'>Add Merchant</h1>
    )

    const fetchInterests = async () => {
        try {
            setLoading(true)
            await GetInterests().then(response => {
                if (response?.status === 200) {
                    setInterests(response?.data?.data)
                } else {
                    showToast('error', 'Error Getting Interests')
                }
            })
        } catch (error) {
            showToast('error', 'Error fetching interests.')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (props.visible) {
            fetchInterests()
        }
    }, [props.visible])

    return (
        <Dialog
            header={header}
            visible={props.visible}
            onHide={() => props.setVisible(false)}
            className='w-auto'
        >
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    type: '',
                    merchantName: '',
                    interestUuids: '',
                    weekdaystart: new Date(),
                    weekdayend: new Date(),
                    weekendstart: new Date(),
                    weekendend: new Date()
                }}
                validate={values => {
                    const errors = {};
                    if (!values.firstName) {
                        errors.firstName = 'First name is required.';
                    }
                    if (!values.lastName) {
                        errors.lastName = 'Last name is required.';
                    }

                    if (!values.phone) {
                        errors.phone = 'Phone is required.';
                    }

                    if (!values.type) {
                        errors.type = 'Type is required.';
                    }
                    if (!values.merchantName) {
                        errors.merchantName = 'Merchant name is required.';
                    }
                    if (!values.interestUuids) {
                        errors.interestUuids = 'Interest is required.';
                    }
                    if (!values.email) {
                        errors.email = 'Email is required';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = 'Invalid email address';
                    }
                    return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true)
                    // values.location = {
                    //     "latitude": 2558,
                    //     "longitude": 123
                    // }
                    values.workingHours = {
                        weekday: {
                            startTime: values.weekdaystart,
                            endTime: values.weekdayend
                        },
                        weekend: {
                            startTime: values.weekendstart,
                            endTime: values.weekendend
                        }
                    }

                    delete values.weekdaystart
                    delete values.weekdayend
                    delete values.weekendstart
                    delete values.weekendend

                    await CreateMerchant(values).then(response => {
                        if (response?.status === 200) {
                            const newMerchant = response?.data?.data?.created
                            props.setMerchants(prevMerchants => [...prevMerchants, newMerchant])
                            props.setTotalRecords(prevTotalRecords => prevTotalRecords + 1)
                            showToast('success', 'Merchant created successfully.')
                            props.setVisible(false)
                        } else {
                            return showToast('error', response?.data?.error ? response?.data?.error : 'Error creating merchant.')
                        }
                    }).catch(error => {
                        console.error('Error submitting form:', error);
                        showToast('error', 'An error occurred while submitting the form.');
                    })
                        .finally(() => {
                            setLoading(false)
                        });
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <form onSubmit={handleSubmit} className='text-custom-blue flex flex-col items-center gap-6 mt-6 justify-between w-full'>
                        <div className='flex lg:flex-row flex-col gap-5 justify-between w-full'>
                            {/* First Name */}
                            <div className="flex flex-col gap-2 w-full">
                                <span className="p-float-label">
                                    <Field as={InputText} id="firstName" name="firstName" className="w-full" />
                                    <label htmlFor="firstName">First Name <Required /></label>
                                </span>
                                <ErrorMessage name="firstName" component="div" className="text-red-500" />
                            </div>
                            {/* Last Name */}
                            <div className="flex flex-col gap-2 w-full">
                                <span className="p-float-label">
                                    <Field as={InputText} id="lastName" name="lastName" className="w-full" />
                                    <label htmlFor="lastName">Last name <Required /></label>
                                </span>
                                <ErrorMessage name="lastName" component="div" className="text-red-500" />
                            </div>
                            {/* Email */}
                            <div className="flex flex-col gap-2 w-full">
                                <span className="p-float-label">
                                    <Field as={InputText} id="email" name="email" className="w-full" />
                                    <label htmlFor="email">Email <Required /></label>
                                </span>
                                <ErrorMessage name="email" component="div" className="text-red-500" />
                            </div>
                            {/* Phone Number */}
                            <div className="flex flex-col gap-2 w-full">
                                <span className="p-float-label">
                                    <Field as={InputText} id="phone" label={"phone"} name="phone" value={values?.phone} onChange={handleChange} className="w-full" />
                                    <label htmlFor="phone">Phone <Required /></label>
                                </span>
                                <ErrorMessage name="phone" component="div" className="text-red-500" />
                            </div>
                        </div>

                        <div className='flex lg:flex-row flex-col gap-5 items-center w-full'>
                            {/* Merchant Name */}
                            <div className="flex flex-col gap-2 w-full">
                                <span className="p-float-label">
                                    <Field as={InputText} id="merchantName" label={"merchantName"} name="merchantName" value={values?.merchantName} onChange={handleChange} className="w-full" />
                                    <label htmlFor="merchantName">Merchant Name <Required /></label>
                                </span>
                                <ErrorMessage name="merchantName" component="div" className="text-red-500" />
                            </div>
                            {/* Description */}
                            <div className="flex flex-col gap-2 w-full">
                                <span className="p-float-label">
                                    <Field as={InputText} id="description" label={"description"} name="description" value={values?.description} onChange={handleChange} className="w-full" />
                                    <label htmlFor="description">Description</label>
                                </span>
                                <ErrorMessage name="description" component="div" className="text-red-500" />
                            </div>
                            {/* Address */}
                            <div className="flex flex-col gap-2 w-full">
                                <span className="p-float-label">
                                    <Field as={InputText} id="address" label={"address"} name="address" value={values?.address} onChange={handleChange} className="w-full" />
                                    <label htmlFor="address">Address</label>
                                </span>
                                <ErrorMessage name="address" component="div" className="text-red-500" />
                            </div>
                            {/* Website */}
                            <div className="flex flex-col gap-2 w-full">
                                <span className="p-float-label">
                                    <Field as={InputText} id="website" label={"website"} name="website" value={values?.website} onChange={handleChange} className="w-full" />
                                    <label htmlFor="website">Website</label>
                                </span>
                                <ErrorMessage name="website" component="div" className="text-red-500" />
                            </div>
                        </div>
                        <div className='flex lg:flex-row flex-col gap-5 items-center justify-between w-full'>
                            {/* Type */}
                            <div className="flex flex-col gap-2 w-full">
                                <span className="p-float-label">
                                    <Field
                                        as={Dropdown}
                                        id="type"
                                        name="type"
                                        value={values.type}
                                        options={types || null}
                                        optionLabel="name"
                                        optionValue="code"
                                        onChange={handleChange}
                                        placeholder="Select Type"
                                        className="w-full"
                                    />
                                    <label htmlFor="type">Type <Required /></label>
                                </span>
                                <ErrorMessage name="type" component="div" className="text-red-500" />
                            </div>
                            {/* Interests */}
                            <div className="flex flex-col gap-2 w-full">
                                <Field
                                    as={MultiSelect}
                                    id="interestUuids"
                                    name="interestUuids"
                                    value={values.interestUuids}
                                    options={interests || null}
                                    optionLabel="interestName"
                                    optionValue="uuid"
                                    onChange={handleChange}
                                    placeholder="Select Interests"
                                    className="w-full"
                                    filter
                                    showClear
                                />
                                <ErrorMessage name="interestUuids" component="div" className="text-red-500" />
                            </div>
                            {/* Location */}
                            {/* <div className="flex flex-col w-full">
                                <span className="p-float-label">
                                    <Field as={InputText} id="Location" label={"Location"} name="Location" className="w-full" />
                                    <label htmlFor="Location">Location</label>
                                </span>
                                <ErrorMessage name="Location" component="div" className="text-red-500" />
                            </div> */}
                        </div>
                        <label className='text-cyan-500'>Working Hours</label>
                        <div className='flex lg:flex-row flex-col w-full'>
                            <div className='flex-1 flex flex-col gap-4'>
                                <label >Weekdays</label>
                                <div className='flex lg:flex-row flex-col gap-5 items-center w-full'>
                                    {/* Weekday Start Time */}
                                    <div className="flex flex-col gap-2">
                                        <span className="p-float-label">
                                            <Field as={Calendar} timeOnly={true} hourFormat="12" id="weekdaystart" label={"weekdaystart"} name="weekdaystart" />
                                            <label htmlFor="weekdaystart">Start Time <Required /></label>
                                        </span>
                                        <ErrorMessage name="weekdaystart" component="div" className="text-red-500" />
                                    </div>
                                    {/* Weekday End Time */}
                                    <div className="flex flex-col gap-2">
                                        <span className="p-float-label">
                                            <Field as={Calendar} timeOnly={true} hourFormat="12" id="weekdayend" label={"weekdayend"} name="weekdayend" />
                                            <label htmlFor="weekdayend">End Time <Required /></label>
                                        </span>
                                        <ErrorMessage name="weekdayend" component="div" className="text-red-500" />
                                    </div>
                                </div>
                            </div>
                            <div className='flex-1 flex flex-col gap-4'>
                                <label>Weekends</label>
                                <div className='flex lg:flex-row flex-col gap-5 items-center w-full'>
                                    {/* Weekday Start Time */}
                                    <div className="flex flex-col gap-2">
                                        <span className="p-float-label">
                                            <Field as={Calendar} timeOnly={true} hourFormat="12" id="weekendstart" label={"weekendstart"} name="weekendstart" />
                                            <label htmlFor="weekendstart">Start Time <Required /></label>
                                        </span>
                                        <ErrorMessage name="weekendstart" component="div" className="text-red-500" />
                                    </div>
                                    {/* Weekday End Time */}
                                    <div className="flex flex-col gap-2">
                                        <span className="p-float-label">
                                            <Field as={Calendar} timeOnly={true} hourFormat="12" id="weekendend" label={"weekendend"} name="weekendend" />
                                            <label htmlFor="weekendend">End Time <Required /></label>
                                        </span>
                                        <ErrorMessage name="weekendend" component="div" className="text-red-500" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-full'>
                            <Button
                                type="submit"
                                label='Submit'
                                className="bg-custom-blue text-white hover:bg-light-blue-2 hover:text-custom-blue border-custom-blue w-full"
                                disabled={isSubmitting}
                            />
                        </div>
                    </form>
                )}
            </Formik>
        </Dialog>
    )
}

export default AddMerchant