import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import PagesLayout from "../../layout/pagesLayout";
import { showToast } from "../../App";
import { Tag } from "primereact/tag";
import * as XLSX from 'xlsx';
import AddEvent from "./AddEvent";
import EditEvent from "./EditEvent";
import { ActivateEvent, DeleteEvent, GetEvents, PublishEvent } from "../../network/Events.network";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ConvertDateTime } from "../../helpers/convertDateTime";
import EventDocuments from "./EventDocuments";
import EventImages from "./EventImages";

const items = [{ label: "Events" }];

function Events(props) {
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [events, setEvents] = useState([]);
    const [addModalVisible, setAddModalVisible] = useState(false)
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState({})
    const [imageVisible, setImageVisible] = useState(false)
    const [documentVisible, setDocumentVisible] = useState(false)

    const dt = useRef(null);

    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            title: { value: "", matchMode: "contains" },
            description: { value: "", matchMode: "contains" },
            startDate: { value: "", matchMode: "contains" },
            endDate: { value: "", matchMode: "contains" },
            keyPoints: { value: "", matchMode: "contains" },
            createdAt: { value: "", matchMode: "contains" },
        },
    });

    const exportXLSX = () => {
        const exportData = events;
        if (exportData?.length > 0) {
            const exportedColumns = exportColumns.map((col) => col.dataKey);
            const exportedRows = exportData.map((cdrmessages) =>
                exportedColumns.map((col) => {
                    const value = cdrmessages[col];
                    return value;
                })
            );
            const worksheetData = [exportedColumns, ...exportedRows];

            const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Management Senders');

            // Generate a unique file name based on the current timestamp
            const fileName = 'users.xlsx';

            XLSX.writeFile(workbook, fileName);
        } else {
            showToast('error', 'No data found')
        }
    };

    //Columns and data to be displayed in exported PDF and Excel files
    const cols = [
        { field: "firstName", header: "First Name" },
        { field: "lastName", header: "Last Name" },
        { field: "title", header: "Title" },
        { field: "bio", header: "Bio" },
        { field: "email", header: "Email" },
        { field: "phone", header: "Phone" }

    ];

    const exportColumns = cols.map((col) => ({
        title: col.header,
        dataKey: col.field,
    }));

    const fetchEvents = async () => {
        try {
            setLoading(true)
            await GetEvents().then(response => {
                if (response?.status === 200) {
                    setEvents(response?.data?.data)
                    setTotalRecords(response?.data?.data?.length)
                } else {
                    showToast('error', 'Error Getting Events')
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchEvents()
    }, []);


    const applyPaginationAndFilters = () => {
        let filteredData = events;

        if (!events) {
            return []; // Return an empty array if tableRecords data is null
        }
        // Apply filters
        Object.keys(lazyState.filters).forEach((key) => {
            const filterValue = lazyState.filters[key].value;
            if (filterValue) {
                filteredData = filteredData.filter((item) =>
                    String(item[key]).toLowerCase().includes(filterValue.toLowerCase())
                );
            }
        });

        // Apply sorting
        if (lazyState.sortField && lazyState.sortOrder) {
            const sortMultiplier = lazyState.sortOrder === 1 ? 1 : -1;
            filteredData.sort((a, b) => {
                const valueA = a[lazyState.sortField];
                const valueB = b[lazyState.sortField];
                if (valueA < valueB) {
                    return -1 * sortMultiplier;
                } else if (valueA > valueB) {
                    return 1 * sortMultiplier;
                }
                return 0;
            });
        }

        // Apply pagination
        const { first, rows } = lazyState;
        const paginatedData = filteredData.slice(first, first + rows);

        return paginatedData;
    };

    const onPage = (event) => {
        setlazyState(event);
    };
    const onSort = (event) => {
        setlazyState(event);
    };


    const header = (
        <div className="flex items-center justify-between gap-2">
            <Button
                type="button"
                icon="pi pi-file"
                severity="primary"
                onClick={() => exportXLSX()}
                className='bg-green-800 text-white hover:bg-white hover:text-green-800 border border-green-800'
                tooltip="Export to Excel"
            />
            <Button
                label='Create'
                type="button"
                icon="pi pi-plus"
                onClick={() => setAddModalVisible(true)}
                tooltip="Add New Event"
                className="bg-custom-blue text-white hover:bg-white hover:text-custom-blue border-custom-blue"
                tooltipOptions={{ position: 'top' }}
            />
        </div>
    );

    const handleActivate = async (rowData) => {
        try {
            setLoading(true);
            let data = { isActive: rowData?.isActive ? false : true }
            const response = await ActivateEvent(rowData?.uuid, data);
            if (response?.status === 200) {
                setEvents(prev => {
                    return prev.map(event => {
                        if (event.uuid === rowData?.uuid) {
                            return {
                                ...event, // Spread the existing admin data
                                isActive: response?.data?.data?.isActive
                            };
                        } else {
                            return event;
                        }
                    });
                });
                showToast('success', `${rowData?.isActive ? 'Event Inactivated successfully!' : 'Event activated sucessfully!'}`)
            } else {
                showToast('error', 'Error activating event');
            }
        } catch (error) {
            console.error('Something went wrong: ', error);
            showToast('error', 'Something went wrong. Please try again');
        } finally {
            setLoading(false);
        }
    }

    const handlePublish = async (rowData) => {
        try {
            setLoading(true);
            let data = { isPublished: rowData?.isPublished ? false : true }
            const response = await PublishEvent(rowData?.uuid, data);
            if (response?.status === 200) {
                setEvents(prev => {
                    return prev.map(event => {
                        if (event.uuid === rowData?.uuid) {
                            return {
                                ...event,
                                isPublished: data?.isPublished
                            };
                        } else {
                            return event;
                        }
                    });
                });
                showToast('success', `${rowData?.isPublished ? 'Event Unpublished successfully!' : 'Event published sucessfully!'}`)
            } else {
                showToast('error', 'Error publishing event');
            }
        } catch (error) {
            console.error('Something went wrong: ', error);
            showToast('error', 'Something went wrong. Please try again');
        } finally {
            setLoading(false);
        }
    }

    const actionTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="flex flex-row gap-2 items-center">
                    <Button tooltip={rowData?.isActive ? 'set Inactive' : 'Activate'} onClick={() => handleActivate(rowData)} tooltipOptions={{ position: 'top' }} icon={rowData?.isActive ? 'pi pi-minus' : "pi pi-check"} style={{ height: '30px', width: '35px' }} size="small" className={`${rowData?.isActive ? 'bg-red-700 hover:text-red-700 hover:border-red-700 border-red-700 text-white' : 'bg-green-800 hover:text-green-800 hover:border-green-800 border-green-800 text-white'} hover:bg-white `} />
                    <Button tooltip={rowData?.isPublished ? 'Unpublish' : 'Publish'} onClick={() => handlePublish(rowData)} tooltipOptions={{ position: 'top' }} icon={rowData?.isPublished ? 'pi pi-minus-circle' : "pi pi-check-circle"} style={{ height: '30px', width: '35px' }} size="small" className={`${rowData?.isPublished ? 'bg-red-700 hover:text-red-700 hover:border-red-700 border-red-700 text-white' : 'bg-green-800 hover:text-green-800 hover:border-green-800 border-green-800 text-white'} hover:bg-white `} />
                    <Button tooltip="Edit Event" onClick={() => { setEditModalVisible(true); setSelectedEvent(rowData) }} tooltipOptions={{ position: 'top' }} icon="pi pi-pencil" style={{ height: '30px', width: '35px' }} size="small" className="bg-custom-blue text-white hover:bg-white hover:text-custom-blue border-custom-blue" />
                    <Button tooltip="Images" onClick={() => { setSelectedEvent(rowData); setImageVisible(true) }} tooltipOptions={{ position: 'top' }} icon="pi pi-image" style={{ height: '30px', width: '35px' }} size="small" className="bg-custom-blue text-white hover:bg-white hover:text-custom-blue border-custom-blue" />
                    <Button tooltip="Documents" onClick={() => { setSelectedEvent(rowData); setDocumentVisible(true) }} tooltipOptions={{ position: 'top' }} icon="pi pi-file" style={{ height: '30px', width: '35px' }} size="small" className="bg-custom-blue text-white hover:bg-white hover:text-custom-blue border-custom-blue" />
                    <Button icon="pi pi-delete-left" tooltip="Delete Event" tooltipOptions={{ position: 'top' }} onClick={() => confirm2(rowData)} style={{ height: '30px', width: '35px' }} size="small" className="bg-red-700 hover:bg-white hover:text-red-700 border-red-700 hover:border-red-700" />
                </div>
            </React.Fragment>
        );
    };


    const accept = async (uuid) => {
        await DeleteEvent({ uuids: [uuid] }).then(response => {
            if (response?.status === 200) {
                const newAdmins = events.filter(admin => admin?.uuid !== uuid)
                setEvents(newAdmins)
                return showToast('success', 'Event deleted successfully')
            } else {
                return showToast('error', 'Error deleting Event. Please try again!')
            }
        }).catch((error) => {
            console.error('Error deleting Event:', error);
            showToast('error', 'An error occurred while deleting Event.');
        })
    }

    const confirm2 = (data) => {
        const acceptWithUserData = () => {
            // Pass the data to the accept function
            accept(data?.uuid);
        };
        confirmDialog({
            message: 'Do you want to delete this event?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-danger',
            accept: acceptWithUserData
        });
    };


    const handleActive = (rowData) => {
        if (rowData?.isActive) {
            return <Tag severity="success" value="Active"></Tag>
        } else {
            return <Tag severity="danger" value="Inactive"></Tag>
        }
    }

    const onFilter = (event) => {
        event["first"] = 0;
        setlazyState(event);
    };

    const publishedBodyTemplate = (rowData) => {
        if (rowData?.isPublished) {
            return <Tag severity="success" value="Published"></Tag>
        } else {
            return <Tag severity="danger" value="Unpublished"></Tag>
        }
    }

    const handleAllDay = (rowData) => {
        if (rowData?.allDay) {
            return <Tag severity="primary" value="All Day"></Tag>
        } else {
            return <Tag severity="primary" value="Not All Day"></Tag>
        }
    }

    return (
        <PagesLayout
            items={items}
            title='Events'
        >
            <ConfirmDialog />
            <DataTable
                value={applyPaginationAndFilters()}
                header={header}
                lazy
                dataKey="uuid"
                paginator
                first={lazyState.first}
                totalRecords={totalRecords}
                rows={lazyState.rows}
                onPage={onPage}
                onSort={onSort}
                sortField={lazyState.sortField}
                sortOrder={lazyState.sortOrder}
                onFilter={onFilter}
                filters={lazyState.filters}
                filterDisplay="row"
                loading={loading}
                ref={dt}
                scrollable
                scrollHeight="60vh"
                selectionMode="single"
                rowsPerPageOptions={[5, 10, 25]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} events"
            >
                <Column field="title" header="Title" sortable filter filterPlaceholder="Search" />
                <Column field="description" header="Description" sortable filter filterPlaceholder="Search" />
                <Column field="startDate" header="Start Date" sortable filter body={rowData => ConvertDateTime(rowData?.startDate)} filterPlaceholder="Search" />
                <Column field="endDate" header="End Date" sortable filter body={rowData => ConvertDateTime(rowData?.endDate)} filterPlaceholder="Search" />
                <Column field="allDay" header="All Day" body={rowData => handleAllDay(rowData)} />
                {/* <Column field="keyPoints" header="Key Points" sortable filter filterPlaceholder="Search" /> */}
                <Column field="isActive" header="Active" body={handleActive} />
                <Column field="isPublished" header="Published" body={rowData => publishedBodyTemplate(rowData)} />
                <Column field="showMembers" header="Show Members" />
                <Column field="createdAt" header="Created date" sortable filter body={rowData => ConvertDateTime(rowData?.createdAt)} filterPlaceholder="Search" />
                <Column frozen className="actionwidth" body={actionTemplate} />
            </DataTable>
            {addModalVisible && <AddEvent
                visible={addModalVisible}
                setVisible={setAddModalVisible}
                setEvents={setEvents}
                setTotalRecords={setTotalRecords}
            />}
            {editModalVisible && <EditEvent
                visible={editModalVisible}
                setVisible={setEditModalVisible}
                events={events}
                setEvents={setEvents}
                event={selectedEvent}
            />}
            {documentVisible && <EventDocuments
                visible={documentVisible}
                setVisible={setDocumentVisible}
                event={selectedEvent}
                setEvents={setEvents}
            />}
            {imageVisible && <EventImages
                visible={imageVisible}
                setVisible={setImageVisible}
                event={selectedEvent}
                setEvents={setEvents}
            />}
        </PagesLayout>

    );
}

export default Events;
